import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import addisons1 from "../../images/addisons1.jpg"
import addisons2 from "../../images/addisons2.jpg"
import addisons3 from "../../images/addisons3.jpg"
import addisons4 from "../../images/addisons4.jpg"
import addisons5 from "../../images/addisons5.jpg"
import addisons6 from "../../images/addisons6.jpg"
import addisons7 from "../../images/addisons7.jpg"
import addisons8 from "../../images/addisons8.jpg"
import addisons9 from "../../images/addisons9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Addison's Disease">
    <div className="fade">
      <p>Addison’s disease, or hypoadrenocorticism, is a disease in which your dog does not produce a few important hormones.  These hormones include cortisol and aldosterone, which are important for regulation of many essential metabolic processes. These hormones are normally produced by the adrenal glands, small organs near your dog’s kidneys.  Without these hormones, your dog’s body cannot respond appropriately during times of stress and will not be able to maintain its blood volume or electrolyte balance.</p>
    </div>
    <Carousel header="What and Why">
      <div image={addisons1} boldtext="Dogs with Addison’s disease don’t feel well and may show vague, nonspecific signs of disease." text="They may eat less, be tired or weak, vomit, or have diarrhea.  They may have weight loss, excessive drinking and urination, or dark, tarry stool that contains digested blood.  These signs may come on gradually or wax and wane and are often worse during times of stress." />
      <div image={addisons2} boldtext="Dogs usually develop Addison’s disease because their immune system attacks and destroys the adrenal glands." text="In rare cases, part of the brain, the hypothalamus or pituitary gland, does not properly regulate the adrenal glands.  Abruptly stopping steroid medications can also cause Addison’s disease. Be careful to follow your veterinarian’s instructions when reducing or discontinuing your dog’s steroid dose." />
      <div image={addisons3} boldtext="An Addisonian crisis is a more life-threatening condition that requires immediate veterinary attention and hospitalization." text="Due to electrolyte imbalances and low blood sugar, a dog in an Addisonian crisis may have a dangerously low heart rate and an abnormal heart rhythm.  They may collapse, shake, or have seizures.  These dogs need intravenous fluids and hospitalization." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={addisons4} boldtext="Addison’s disease can look like a lot of other diseases." text="On physical examination, your veterinarian may notice a painful belly or signs of dehydration. Blood work, including a complete blood count and serum chemistry, may uncover important clues consistent with Addison’s disease or may help rule out other diseases.  Imaging with x-rays sometimes reveals a small heart or a large esophagus, and abdominal ultrasound may reveal small adrenal glands." />
      <div image={addisons5} boldtext="If your veterinarian suspects Addison’s disease, they may perform a blood test to measure your dog’s blood cortisol level." text="If this level is above a certain amount, Addison’s disease can be ruled out, and your veterinarian can confidently look for other causes of your dog’s illness.  If your dog’s cortisol level is low, further testing can confirm a diagnosis of Addison’s disease." />
      <div image={addisons6} boldtext="Your veterinarian will use the ACTH stimulation test to confirm a diagnosis of Addison’s disease." text="This test measures blood cortisol before and after administration of a signaling molecule that tells the adrenal glands to start working.  This test takes about an hour, and dogs with Addison's disease will not respond to the signal." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={addisons7} boldtext="Long-term treatment for Addison’s disease aims to replace the hormones that the adrenal glands are not producing." text="This usually includes daily administration of a relatively low dose of an oral steroid, prednisone.  An injectable medication, DOCP, is given every 21 to 30 days.  During times of stress, such as boarding, grooming, travel, or veterinary visits, your veterinarian may increase the dose of the oral steroid." />
      <div image={addisons8} boldtext="In rare cases, dogs with atypical Addison’s disease may only be deficient in the stress hormone, cortisol." text="Your veterinarian will prescribe a steroid to replace cortisol, but DOCP injections are not needed.  These dogs have an easier time maintaining their electrolytes and are less likely to have an Addisonian crisis.  This disease often progresses to full-blown Addison’s disease, so your veterinarian will monitor your dog closely for signs of disease progression." />
      <div image={addisons9} boldtext="Dogs with Addison’s disease usually live long, happy lives with medical treatment." text="As your veterinarian monitors and adjusts your dog’s medications, your dog may require a few veterinary visits in the weeks following a diagnosis.  Once your dog’s medications have been tailored to meet his or her needs, your dog will only require quick visits for DOCP injections and regular checkups every 6 months.  Some pet parents eventually become comfortable giving the DOCP injections at home." />
    </Carousel>
    </TopicWraper>
  </div>;
