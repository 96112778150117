import React from 'react';

export default () =>
  <div className="footer">
    <footer>
    <div style={{display: "block"}}>
      <a target="_blank" rel="noopener noreferrer" href="https://jamesoldeschulte.com">JamesOldeschulte.com</a>
    </div>
    </footer>
  </div>;
