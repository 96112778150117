import React from 'react';
import { Route, Switch } from "react-router-dom";
import './App.css';

import AllHandouts from "./components/AllHandouts";
import Addisons from "./components/topicpages/Addisons";
import Aki from "./components/topicpages/AKI"
import Allergies from "./components/topicpages/Allergies";
import Anesthesia from "./components/topicpages/Anesthesia";
import Cancer from "./components/topicpages/Cancer";
import Ulcers from "./components/topicpages/Ulcers";
import Ccl from "./components/topicpages/CCL";
import Ckd from "./components/topicpages/CKD"

import Cushings from "./components/topicpages/Cushings";
import Diabetes from "./components/topicpages/Diabetes";
import DCM from "./components/topicpages/DCM";
import DietDCM from "./components/topicpages/DietDCM";
import Ear from "./components/topicpages/Ear";
import Bites from "./components/topicpages/Bites";
import Fireworks from "./components/topicpages/Fireworks";
import Gastropexy from "./components/topicpages/Gastropexy";
import HipDysplasia from "./components/topicpages/HipDysplasia";
import Hyperthyroidism from "./components/topicpages/Hyperthyroid";

import Hypothyroid from "./components/topicpages/Hypothyroid";
import Shunts from "./components/topicpages/Shunts";
import Osteoarthritis from "./components/topicpages/Oa";
import OaManagement from "./components/topicpages/OaManagement";
import Parvovirus from "./components/topicpages/Parvo";
import Seizures from "./components/topicpages/Seizures";
import Vhd from "./components/topicpages/VHD";
import NotFound from "./components/NotFound";

export default () =>
    <Switch>
      <Route path="/" exact render={() => <div><AllHandouts/></div>} />
      <Route path="/addisons-disease" exact render={() => <div><Addisons/></div>} />
      <Route path="/aki" exact render={() => <div><Aki/></div>} />

      <Route path="/canine-allergies" exact render={() => <div><Allergies/></div>} />
      <Route path="/anesthesia" exact render={() => <div><Anesthesia/></div>} />
      <Route path="/cancer" exact render={() => <div><Cancer/></div>} />
      <Route path="/corneal-ulcers" exact render={() => <div><Ulcers/></div>} />
      <Route path="/ccl" exact render={() => <div><Ccl/></div>} />
      <Route path="/ckd" exact render={() => <div><Ckd/></div>} />

      <Route path="/cushings-syndrome" exact render={() => <div><Cushings/></div>} />
      <Route path="/dcm" exact render={() => <div><DCM/></div>} />
      <Route path="/diabetes" exact render={() => <div><Diabetes/></div>} />
      <Route path="/diet-associated-dcm" exact render={() => <div><DietDCM/></div>} />
      <Route path="/ear-infections" exact render={() => <div><Ear/></div>} />
      <Route path="/bite-wounds" exact render={() => <div><Bites/></div>} />
      <Route path="/fireworks" exact render={() => <div><Fireworks/></div>} />
      <Route path="/gastropexy" exact render={() => <div><Gastropexy/></div>} />
      <Route path="/hip-dysplasia" exact render={() => <div><HipDysplasia/></div>} />
      <Route path="/hyperthyroidism" exact render={() => <div><Hyperthyroidism/></div>} />

      <Route path="/hypothyroidism" exact render={() => <div><Hypothyroid/></div>} />
      <Route path="/liver-shunts" exact render={() => <div><Shunts/></div>} />
      <Route path="/osteoarthritis" exact render={() => <div><Osteoarthritis/></div>} />
      <Route path="/osteoarthritis-management" exact render={() => <div><OaManagement/></div>} />
      <Route path="/parvovirus" exact render={() => <div><Parvovirus/></div>} />
      <Route path="/seizures" exact render={() => <div><Seizures/></div>} />
      <Route path="/vhd" exact render={() => <div><Vhd/></div>} />
      <Route render={() => <div><NotFound/></div>} />
    </Switch>;
