import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import ulcers1 from "../../images/ulcers1.png"
import ulcers2 from "../../images/ulcers2.png"
import ulcers3 from "../../images/ulcers3.png"
import ulcers4 from "../../images/ulcers4.png"
import ulcers5 from "../../images/ulcers5.png"
import ulcers6 from "../../images/ulcers6.png"
import ulcers7 from "../../images/ulcers7.png"
import ulcers8 from "../../images/ulcers8.png"
import ulcers9 from "../../images/ulcers9.png"

export default () =>
  <div>
    <TopicWraper topic="Corneal Ulcers">
    <div className="fade">
      <p>A corneal ulcer involves damage to the surface of your dog’s eye. You may notice that your dog is rubbing at the eye, blinking, squinting, or tearing. Corneal ulcers are extremely painful and require medical treatment, so do not hesitate to bring your dog to his or her veterinarian.</p>
    </div>
    <Carousel header="What and Why">
      <div image={ulcers1} boldtext="Dogs often get corneal ulcers from direct trauma to the eye or when debris gets stuck in the eye." text="Some dogs have extra hairs on their eyelids or around their eyes that rub the surface of the eye. Other dogs do not have enough tears to adequately protect the eye. Your veterinarian will identify the underlying cause of the corneal ulcer so that they can treat your dog appropriately." />
      <div image={ulcers2} boldtext="Corneal ulcers come in a few different varieties and can have complicating factors." text="The depth of the corneal ulcer is important. Deep ulcers can cause the eye to be very fragile and complicate treatment. Infection of the ulcer can also delay healing. Your veterinarian can assess the depth of the ulcer and look for signs of infection." />
      <div image={ulcers3} boldtext="Some dogs have a problem with the way the surface of their eye is formed which leads to an indolent ulcer." text="In the case of an indolent ulcer, loose flaps of tissue at the surface of the eye prevent normal healing. This type of ulcer is seen more commonly in older dogs and Boxers. Indolent ulcers will often require several trips to the veterinarian and special treatment." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={ulcers4} boldtext="Your veterinarian will start by confirming the presence of a suspected corneal ulcer." text="A special green stain, fluorescein, will wash off the surface of a normal eye, but it will not wash away from a corneal ulcer. If your veterinarian confirms the presence of a corneal ulcer, treatment is necessary." />
      <div image={ulcers5} boldtext="To ensure proper treatment, your veterinarian will look for the cause of the corneal ulcer." text="Your veterinarian will examine the eye and eyelids for any abnormal hairs or trapped debris. Your veterinarian will also check that the eye and its structures are functioning normally. The cause of the ulcer is not always identified, and it is assumed that a traumatic injury went unnoticed or the insulting debris has already been washed away." />
      <div image={ulcers6} boldtext="Before treating the corneal ulcer, your veterinarian will assess the depth of the ulcer and look for signs of infection." text="This will include staining and examining the eye and may include a microscopic examination of collected cells or a bacterial culture. If your veterinarian suspects that a chronic dry eye caused the ulcer, they may measure your dog’s tear production." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={ulcers7} boldtext="Most simple corneal ulcers should heal in one week with proper treatment." text="This usually requires putting antibiotic drops or ointment into your dog’s eye several times each day to prevent infection while the surface of the eye heals. A medication that dilates your dog’s pupil may be used to reduce pain and inflammation associated with the corneal ulcer. Preventing your dog from rubbing at and further damaging the eye is also important, so your veterinarian will usually recommend a hard-plastic cone." />
      <div image={ulcers8} boldtext="If the ulcer does not heal despite proper treatment, then it may be a more complicated ulcer." text="Your veterinarian will need to re-examine your dog’s eye and look for complicating factors like infection or signs of an indolent ulcer. Your veterinarian may recommend a visit to a veterinary ophthalmologist for further evaluation." />
      <div image={ulcers9} boldtext="Some deep corneal ulcers make the eye very fragile, and surgery is recommended to prevent further damage to the eye." text="Your veterinarian will recommend that you visit a veterinary ophthalmologist who has special training to do surgery on the eye." />
    </Carousel>
    </TopicWraper>
  </div>;
