import React from 'react';
import TopicWraper from "../TopicWraper";

export default () =>
<div>
  <TopicWraper topic="Osteoarthritis Management">
  <div className="fade">
    <p>Exercise, diet, physical rehabilitation, medications, and joint supplements are all important components of managing your dog's osteoarthritis.  Osteoarthritis is best treated with a combination of these therapies that are tailored to your dog.</p>
  </div>
  <div className="fade">
    <h2>Diet and Exercise</h2>
  </div>
  <div className="fade" style={{marginBottom: "20px"}}>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>A lean body weight helps reduce stress on your dog's joints.</span>  Use <a style={{color: "#337ab7"}} href="https://www.wsava.org/WSAVA/media/Arpita-and-Emma-editorial/Body-Condition-Score-Dog.pdf" target="_blank" rel="noopener noreferrer">this guide from the WSAVA</a>, and aim to keep your dog at a body condition score of 4.  Weight is best lost slowly, so weighing in at your veterinary clinic every 2-4 weeks can help ensure you are making steady progress.  Some dogs will need a weight-loss diet that jump-starts their metabolism, keeps them full, and is rich in EPA fish oils.  Hill's Metabolic and Mobility may be a good option.  Other joint diets, including Hill's j/d are rich in prescription grade fish oils and great for dogs that are already at a healthy weight.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Consistent exercise is key.</span> Dogs with osteoarthritis need regular, consistent exercise. Big outings to the dog park, beach, or trails will often cause inflammation and pain and should be avoided.  Swimming is great, low-impact exercise and a good option for dogs that enjoy the water.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Physical rehabilitation exercises can help your dog maintain muscle mass and balance.</span>  Slow leash walking, sit to stand exercises, <a style={{color: "#337ab7"}} href="https://amzn.to/30r8dB1" target="_blank" rel="noopener noreferrer">weight-shifting exercises</a>, stairs climbing, and <a style={{color: "#337ab7"}} href="https://amzn.to/30tdYhr" target="_blank" rel="noopener noreferrer">Cavaletti rails</a> can all be useful.  Ask your veterinarian to tailor a rehab plan for your individual dog's needs.</p>
  </div>
  <div className="fade">
    <h2>Medications</h2>
  </div>
  <div className="fade" style={{marginBottom: "20px"}}>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Non-steroidal Anti-inflammatory drugs (NSAIDs) are an important component of managing your dog's osteoarthritic pain.</span>  They work locally at the affected joints to reduce inflammation.  Some dogs only require them from time to time, around periods of increased activity or changes in the weather.  Some dogs will benefit from daily NSAID use.  The risk is small, but side effects can be serious and include gastrointestinal bleeding.  If your dog stops eating, vomits, has diarrhea, or has dark tarry stool, stop the NSAID medication and call your veterinarian. In general, NSAIDs should not be combined with steroid medications as this will increase the risk of life-threatening side effects.  Over-the-counter human NSAIDs, including aspirin, ibuprofen, and naproxen can cause deadly gastrointestinal perforation, bleeding, or liver damage if given to dogs.  Do not give your dog human pain medications.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Gabapentin and amantadine work to decrease nerve pain and are often combined with an NSAID.</span> Gabapentin has variable effects on dogs and may cause sedation at certain doses.  It may be prescribed long-term and may need to be given three times each day to be most effective.  Amantadine is thought to reset central nerves that are "wound-up" by chronic pain.  In some dogs, a few weeks of amantadine will make NSAID medications more effective.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}><a style={{color: "#337ab7"}} href="https://www.adequancanine.com/" target="_blank" rel="noopener noreferrer">Adequan</a> is an injectable medication that helps maintain healthy cartilage and lubricate joints.</span>  It works within hours and lasts for about 4 weeks at maintenance dosing.  It can be expensive initially, as it requires about 8 injections in the first month to reach adequate levels.</p>
  </div>
  <div className="fade">
    <h2>Supplements</h2>
  </div>
  <div className="fade" style={{marginBottom: "20px"}}>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Eicosapentaenoic acid (EPA) from fish oils (omega-3 fatty acids) reduces inflammatory molecules at joints.</span>  <a style={{color: "#337ab7"}} href="https://amzn.to/30v5bvq" target="_blank" rel="noopener noreferrer">Dog fish oil supplements</a> generally contain more EPA than human supplements.  Prescription diets for joint health are rich in EPA.  Other pump-on and capsule alternatives may be more appropriate for some dogs.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Glucosamine and chondroitin sulfate are popular supplements for human osteoarthritis that have been successfully marketed to dog parents.</span>  Anecdotal reports suggest they have helped many dogs.  Scientific studies question their absorption and availability.  They are relatively safe and often found in <a style={{color: "#337ab7"}} href="https://amzn.to/30teps5" target="_blank" rel="noopener noreferrer">combination products</a> with claims to treat osteoarthritis in dogs.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Avocado and soybean unsaponifiables, green lip mussel, and CBD are all gaining popularity among pet parents.</span> There are many other supplements out there, and some may benefit your individual dog.  Keep in mind there is no regulation of the pet supplement industry, and there is wide variation in quality.</p>
  </div>
  <div className="fade">
    <h2>Complimentary Therapies</h2>
  </div>
  <div className="fade" style={{marginBottom: "20px"}}>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Acupuncture has a strong place in pain-relief, healing, stress-reduction, and tissue healing.</span>  Some dogs benefit greatly from this addition to their osteoarthritis management plan.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Massage, transcutaneous electrical nerve stimulation (TENS), low level LASER therapy (LLLT), extracorporeal shock wave therapy (ESWT), and static magnetic field (SMF) may benefit some dogs with osteoarthritis.</span> Although not widely available, there are veterinary clinics that focus on physical rehabilitation that may be able to offer some of these therapies.</p>
    <p style={{marginBottom: "20px"}}><span style={{fontWeight: "700"}}>Stem cell therapy from adipose-derived mesenchymal stem cells is emerging as an addition to osteoarthritis management.</span> Dogs must be put under anesthesia to collect the fat.  Prepared cells are injected into affected joints.</p>
  </div>
  </TopicWraper>
</div>
;
