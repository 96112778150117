import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import cushings1 from "../../images/cushings1.png"
import cushings2 from "../../images/cushings2.png"
import cushings3 from "../../images/cushings3.png"
import cushings4 from "../../images/cushings4.png"
import cushings5 from "../../images/cushings5.png"
import cushings6 from "../../images/cushings6.png"
import cushings7 from "../../images/cushings7.png"
import cushings8 from "../../images/cushings8.png"
import cushings9 from "../../images/cushings9.png"

export default () =>
  <div>
    <TopicWraper topic="Cushing’s Syndrome">
    <div className="fade">
      <p>Cushing’s syndrome, or canine hyperadrenocorticism, is a syndrome in which your dog produces too much cortisol. Cortisol is a stress hormone that is normally produced by your dog’s adrenal glands. If your dog is producing too much of this stress hormone, the first thing you may notice is that your dog is drinking more, urinating more, and eating more. You may also notice that your canine companion is panting more, has a thin haircoat, or has developed a pot-bellied appearance.</p>
    </div>
    <Carousel header="What and Why">
      <div image={cushings1} boldtext="There are two variations of Cushing’s syndrome in dogs." text="Most commonly, Cushing’s syndrome is a result of too much signaling molecule that is released from part of your dog’s brain. This is the case in about 85% of dogs. In the other 15% of dogs, the adrenal glands in your dog’s abdomen are making too much cortisol despite a normal amount of this signaling molecule." />
      <div image={cushings2} boldtext="In pituitary dependent hyperadrenocorticism (PDH), an area of your dog’s brain produces too much of a signaling hormone, ACTH." text="This hormone tells the adrenal glands to make cortisol. This is the most common type of Cushing’s syndrome. It is more common in middle-aged to older dogs." />
      <div image={cushings3} boldtext="Dogs with functional adrenal tumors make too much stress hormone despite no instruction to do so from their pituitary gland." text="This type of Cushing’s syndrome is less common, but some of the tests your veterinarian may perform can help distinguish this from the more common type." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={cushings4} boldtext="Your veterinarian will likely look at your dog’s urine and perform bloodwork to rule out other common diseases that have signs like Cushing’s syndrome." text="These diseases include diabetes and chronic kidney disease, which are relatively common in the same population of dogs." />
      <div image={cushings5} boldtext="Your veterinarian may try to rule out a diagnosis of Cushing’s syndrome by measuring your dog’s urine cortisol to creatinine ratio (UCCR)." text="This may mean that you must catch your dog’s urine at home for a few days to get the most accurate measurement." />
      <div image={cushings6} boldtext="If Cushing’s syndrome cannot be ruled out, your veterinarian will confirm the diagnosis of Cushing’s syndrome with an endocrine blood test." text="It’s likely that your veterinarian will perform a low-dose dexamethasone suppression test (LDDST). This test will stop the production cortisol in normal dogs, but not in dogs with Cushing’s syndrome." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={cushings7} boldtext="Trilostane is usually the medication of choice for treating Cushing’s syndrome." text="Trilostane given twice daily will reduce the synthesis of cortisol. It will not cure your dog’s Cushing’s syndrome, so your dog will require treatment for life. Trilostane therapy can have dangerous side effects, so your dog will need a recheck appointment with your veterinarian about a week after starting the medication. Your dog may need additional rechecks as your veterinarian personalizes your dog’s medication dose." />
      <div image={cushings8} boldtext="If your dog requires trilostane therapy, your veterinarian will warn you about the potential for dangerous side effects." text="If your dog is acting more tired than usual, stops eating, or vomits, check in with your veterinary team immediately. These may be signs that your dog’s stress hormone levels are too low. Your veterinarian will stop trilostane therapy and recheck blood tests if your dog appears ill, as this could be an effect of the medication." />
      <div image={cushings9} boldtext="Other options for treatment of Cushing’s syndrome exist and may be more appropriate for your dog." text="These options include surgery and other medications that may eliminate the need for daily medication with trilostane. This will depend on the type of Cushing’s syndrome affecting your individual dog. None of these treatments are without risk, so talk to your veterinarian about what is best for your four-legged family member." />
    </Carousel>
    </TopicWraper>
  </div>;
