import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";
import CarouselSingle from "../CarouselSingle"

import ear1 from "../../images/ear1.jpg"
import ear2 from "../../images/ear2.jpg"
import ear3 from "../../images/ear3.jpg"
import ear4 from "../../images/ear4.jpg"
import ear5 from "../../images/ear5.jpg"
import ear6 from "../../images/ear6.jpg"
import ear7 from "../../images/ear7.jpg"
import ear8 from "../../images/ear8.jpg"
import ear9 from "../../images/ear9.jpg"
import ear10 from "../../images/ear10.jpg"
import ear11 from "../../images/ear11.jpg"

export default () =>
  <div>
    <TopicWraper topic="Ear Infections">
    <div className="fade">
      <p>Ear infections are common in dogs, and certain factors put some dogs at increased risk.  Head shaking, scratching, waxy debris, or a foul odor may alert you to your dog’s ear problem, or your veterinarian may notice inflammation of the ear canal during routine veterinary evaluation.  Evaluation by your veterinarian and prompt treatment of any infection is necessary to prevent permanent changes associated with chronic infections.</p>
    </div>
    <Carousel header="What and Why">
      <div image={ear1} boldtext="Many factors contribute to the development of ear infection and inflammation." text="Certain breeds with floppy ears and dogs with skin allergies or other skin disorders are at increased risk.  Moisture from swimming or bathing and high humidity climates also make ear infections more likely.  Once bacteria or yeast have established themselves, they perpetuate the inflammation in the ear canal.  Ear infections are usually painful, and your dog may turn his or her head towards one side because of the discomfort." />
      <div image={ear2} boldtext="Middle ear infections occur when bacteria or yeast extend past the eardrum." text="These infections often present with a ruptured eardrum.  In some cases, a film of bacteria coats the middle ear and makes these infections much harder to treat.  Dogs with chronic ear infections, where debris and infection sit against and erode the eardrum, are more at risk of middle ear infections." />
      <div image={ear3} boldtext="Inner ear infections can develop if middle ear infections are left untreated." text="Once the inner ear is affected, your dog may develop a neurologic head tilt—not to be confused with the head turn caused by a painful external ear canal.  You may also notice difficulty balancing or other neurologic signs." />
    </Carousel>
    <CarouselSingle header="Prevention">
    <div image={ear4} boldtext="Regular ear cleanings every few weeks and following bathing or swimming can loosen waxy debris in the ear canal." text="This should be done gently by squeezing a cotton ball saturated with ear flush into the canal and massaging the base of the ear.  Using cotton swabs is not recommended, as these will not reach the deeper lateral portion of your dog’s L-shaped ear canal and risk traumatizing the canal and causing more inflammation." />
    </CarouselSingle>
    <Carousel header="Treating Simple Infections">
    <div image={ear5} boldtext="Your veterinarian will attempt to visualize the ear canal and eardrum and sample ear debris for evaluation under a microscope." text="Dogs that are painful or have swollen, inflamed ear canals may not allow your veterinarian to look in the ear.  Using a microscope, your veterinarian can identify the type of bacteria or yeast from ear swab samples rolled onto a glass slide." />
    <div image={ear6} boldtext="Most ear infections can be treated by cleaning the external ear canal and treating with an ear medication to kill yeast and bacteria." text="Some of these medications must be administered daily by pet parents at home.  A single treatment in hospital with a medication named Claro is appropriate for many simple ear infections and eliminates the need for daily at-home treatment.  This medication coats the ear canal and treats infection for 30 days.  If your dog’s ears have been treated with Claro, it is important not to clean or allow water into the ears for 30 days." />
    <div image={ear7} boldtext="Oral steroids are often needed to reduce ear inflammation, allowing debris to exit and medication to enter the ear canal." text="Steroids come with side effects, including increased panting, drinking, peeing, and eating.  They generally should not be used long-term or combined with certain non-steroidal anti-inflammatory pain medications." />
    </Carousel>
    <Carousel header="Treating More Complicated Infections">
    <div image={ear8} boldtext="Dogs with chronic, recurrent ear infections, middle ear infections, or rod-shaped bacteria on microscopic evaluation of ear debris may need targeted antibiotic therapy, anesthetized ear cleaning, or surgery." text="General anesthesia is usually required to sample bacteria from the middle ear for culture and sensitivity testing.  Oral antibiotics may be added to your dog’s treatment if your veterinarian suspects middle ear involvement." />
    <div image={ear9} boldtext="Dogs with chronic ear infections that affect the middle ear may benefit from a myringotomy and cleaning of the middle ear while under general anesthesia." text="During this procedure, your veterinarian will go through the eardrum and clean out the middle ear.  Repeat cleanings may be needed." />
    <div image={ear10} boldtext="After months or years of persistent infection and inflammation, the ear canal is permanently affected." text="This may cause the canal to be permanently narrowed, limiting treatment options.  It can be extremely painful.  Dogs with chronic ear infections may benefit from a surgery called a TECABO (Total Ear Canal Ablation and Bulla Osteotomy), in which the ear canal and associated structures are removed.  This surgery is not without risk, but it can permanently eliminate ear infection and relieve discomfort." />
    </Carousel>
    <CarouselSingle header="Aural Hematomas">
    <div image={ear11} boldtext="Aural hematomas occur as a result of head shaking or scratching, when blood fills the floppy part of the ear." text="Treating these generally requires removing the accumulated blood and either placing a temporary drain or surgically closing the space between your dog’s skin and ear cartilage.  This almost always requires heavy sedation.  Ear infection is usually the cause of the ear discomfort and must also be treated." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
