import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";

import aki1 from "../../images/aki1.jpg"
import aki2 from "../../images/aki2.jpg"
import aki3 from "../../images/aki3.jpg"

export default () =>
  <div>
    <TopicWraper topic="Acute Kidney Injury">
    <div className="fade">
      <p>The kidneys are important for filtering waste products from the blood and excreting them in the urine.  They also play a role in electrolyte balance, blood pressure, blood volume, and red blood cell production.  In acute kidney injury, something is impairing the kidney’s ability to do these important jobs.</p>
    </div>
    <CarouselSingle header="What and Why">
      <div image={aki1} boldtext="Acute kidney injury occurs due to toxins, infection, lack of blood flow to the kidneys, or a problem excreting urine." text="Common kidney-damaging toxins include antifreeze (ethylene glycol), certain lilies and other plants, grapes or raisins, and human pain medications. Signs of acute kidney injury may include increased thirst, increased urine production, lack of urine production, lethargy, inappetence, and vomiting. Some of the causes of kidney injury can be corrected and the kidneys will return to normal function.  In some cases, there is lasting damage even once the cause has been identified and corrected.  In other cases—termed “acute-on-chronic”—underlying chronic kidney disease is identified after treating the acute kidney injury." />
    </CarouselSingle>
    <CarouselSingle header="At Your Veterinarian">
      <div image={aki2} boldtext="Blood and urine testing at your veterinarian can identify a kidney problem." text="Poorly concentrated urine, protein in the urine, or increased kidney values on your cat’s blood work may suggest kidney injury.  Certain crystals in your cat’s urine may indicate antifreeze toxicity.  Further testing often includes blood pressure measurement, abdominal x-rays or ultrasound, retrovirus (FIV and FeLV) testing, and urine culture.  The cause of the kidney injury cannot always be identified.  Careful monitoring and repeat blood work are often required, as some of the electrolytes the kidneys regulate are important for your cat’s heartbeat." />
    </CarouselSingle>
    <CarouselSingle header="Treatment">
      <div image={aki3} boldtext="Acute kidney injury almost always requires hospitalization and intravenous (IV) fluid therapy." text="This helps maximize the amount of fluid going to the kidneys and the amount of urine produced.  Fluids help flush toxins out, balance electrolytes, and prevent further kidney injury.  Injectable antibiotics may be given if there is any chance infection caused the kidney injury. Anti-nausea medications, antacids, and appetite stimulants may be helpful.  Some cats will benefit from a feeding tube to maintain adequate nutrition.  Rechecking blood work at least every 24 hours will allow your veterinarian to reassess kidney values and electrolytes and determine if your cat is responding to treatment." />
    </CarouselSingle>
    <div className="fade" style={{paddingBottom: "20px"}}>
      <p>Dialysis—often a treatment for humans with reduced kidney function—is available at certain specialty centers.  Your veterinarian is the best source of information for other treatment options specific to your cat.</p>
    </div>
    </TopicWraper>
  </div>;
