import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import dcm1 from "../../images/dcm1.jpg"
import cancer2 from "../../images/cancer2.jpg"
import dcm3 from "../../images/dcm3.jpg"
import dcm4 from "../../images/dcm4.jpg"
import ccl5 from "../../images/ccl5.jpg"
import dcm7 from "../../images/dcm7.jpg"
import dcm8 from "../../images/dcm8.jpg"
import anesthesia5 from "../../images/anesthesia5.jpg"
import allergies6 from "../../images/allergies6.png"

export default () =>
  <div>
    <TopicWraper topic="Dilated Cardiomyopathy (DCM)">
    <div className="fade">
      <p>In dogs with dilated cardiomyopathy (DCM), the heart gets bigger to make up for lost pumping ability.  This happens when the heart muscle gets weak or has irregular electrical activity. It often leads to heart failure, abnormal heart beats, and sudden death.  Certain breeds are at increased risk of developing DCM including the Doberman, Great Dane, boxer, and many giant-breed dogs.  Feeding grain-free, boutique, exotic-protein, vegan, vegetarian, and home-cooked diets has been associated with DCM in breeds that are not usually at risk.  More information about diet-associated DCM can be found <a target="" rel="noopener noreferrer" href='/diet-associated-dcm'>here</a>.</p>
    </div>
    <Carousel header="What and Why">
      <div image={dcm1} boldtext="In dogs with DCM, the heart loses its ability to efficiently pump blood through the body." text="The chambers of the heart get bigger as blood backs up, further thinning and weakening the heart muscle.  Some of these dogs have irregular heart rhythms (arrhythmias) that lead to or are a result of heart chamber dilation.  This disease is most common in large and giant breed dogs.  It has been associated with certain diets and dog food brands." />
      <div image={cancer2} boldtext="Genetic factors make this disease more likely in certain breeds including the Doberman, Great Dane, boxer, Irish wolfhound, German shepherd, cocker spaniel, and golden retriever." text="Some of these breeds may benefit from screening tests at regular veterinary visits.  Some asymptomatic dogs will benefit from starting medical treatment to improve heart function and prevent irregular heartbeats." />
      <div image={dcm3} boldtext="Dogs with enlarged hearts and abnormal heart beats may tire easily, become weak, collapse, have decreased appetite, or lose weight." text="They may develop heart failure and fluid may build up in their lungs or bellies.  You may notice increased breathing rate, increased breathing effort, or coughing.  Unfortunately, almost one in three dogs with DCM will die suddenly without showing other clinical signs." />
    </Carousel>
    <Carousel header="At your veterinarian">
    <div image={dcm4} boldtext="Some dogs with DCM have heart murmurs or abnormal heart beats that are heard when your veterinarian listens to your dog’s heart." text="Your veterinarian may recommend further tests including blood tests, ultrasound imaging of the heart, x-ray imaging of the heart, or measuring the electrical activity of the heart." />
    <div image={ccl5} boldtext="An echocardiogram, often abbreviated echo, is the best way for your veterinarian to evaluate the structure and function of your dog’s heart and heart valves." text="This test is usually performed by a veterinary cardiologist, a specialist with additional training and experience in cardiology. An echocardiogram is an ultrasound of the heart.  It is the best test to see the heart pump and measure the size of individual heart chambers.  This can help personalize your dog’s treatment plan." />
    <div image={dcm8} boldtext="In addition to an echocardiogram, your veterinarian will likely recommend other testing." text="X-ray images can be used to measure the size of the heart and may help determine which medications to start.    Blood pressure measurement may also be suggested.  An ECG, which measures the electrical activity of the heart, may help identify abnormal heart beats.  Blood tests can be used to screen for stretching of the heart muscle wall and to evaluate the function of your dog’s kidneys and liver." />
    </Carousel>
    <Carousel header="Treatment and Monitoring">
    <div image={dcm7} boldtext="Many heart medications may be used to slow the dilation of the heart, help the heart pump more efficiently, or prevent abnormal heart beats." text="These medications are tailored to the individual dog and usually require recheck appointments.  Doses of these medications may need to be adjusted as DCM progresses.  Dogs with DCM that have no clinical signs generally live 1-4 years without developing symptoms.  Dogs with symptoms may only survive months, but many dogs outlive these averages." />
    <div image={allergies6} boldtext="Dogs with DCM that have been on diets associated with the disease should be transitioned to a new diet." text=" Hill’s, Purina, and Royal Canin are the three biggest veterinary food brands.  They are generally well-trusted in the veterinary community.  Taurine supplementation may be beneficial for some dogs with diet-associated DCM.  Some of the structural changes may be reversible, and a repeat echocardiogram may show improvement after several months." />
    <div image={anesthesia5} boldtext="Resting respiratory rate, counted at home while your dog is asleep, can be the most useful indicator that your dog is in early heart failure." text="This is measured by watching the rise and fall of your dog’s chest as it sleeps and counting the number of breaths in 60 seconds.  You can count for 30 seconds and double the number of breaths counted.  As fluid backs up into your dog’s lungs, the resting respiratory rate increases.  Normally, dogs breathe less than 30 breaths each minute while they are sleeping." />
    </Carousel>
    </TopicWraper>
  </div>;
