import React from 'react';
import TopicWraper from "../TopicWraper";

export default () =>
  <div>
    <TopicWraper topic="Diet-Associated Dilated Cardiomyopathy">
    <div className="fade">
      <p><a target="" rel="noopener noreferrer" href='/dcm'>Dilated cardiomyopathy</a> is a disease in which the heart loses its pumping ability and gets bigger.  It causes reduced heart function, heart failure, and sudden death in many dogs.</p>
    </div>
    <div className="fade">
      <h2>Feeding grain-free, boutique, exotic-protein, vegan, vegetarian, and home-cooked diets has been associated with DCM in breeds that are not usually at risk. </h2>
    </div>
    <div className="fade" style={{marginBottom: "20px"}}>
      <p>This has been studied in thousands of dogs across several veterinary teaching hospitals, but the link is not well understood.  The association between diet and dilated cardiomyopathy was first reported in the mid-1990’s.  The FDA opened an investigation in July 2018.  On June 27, 2019, <a target="_blank" rel="noopener noreferrer" href='https://www.fda.gov/animal-veterinary/news-events/fda-investigation-potential-link-between-certain-diets-and-canine-dilated-cardiomyopathy'>the FDA published an update</a> naming the brands fed in most of the DCM cases reported.  These brands include Acana, Zignature, Taste of the Wild, 4Health, Earthborn Holistic, Blue Buffalo, Nature’s Domain, Fromm, Merrick, California Natural, Natural Balance, Orijen, Nature’s Variety, NutriSource, Nutro, and Rachael Ray Nutrish.</p>
    </div>
    <div className="fade">
      <p>There is more money in the pet food industry than any other segment of the veterinary industry.  There are relatively few board-certified veterinary nutritionists, specialists with additional training in animal nutrition.  There is little enforced regulation of pet foods or their manufacturers.  Many pet food companies do not employ or consult with veterinary nutritionists, do not perform <a target="_blank" rel="noopener noreferrer" href='https://petfood.aafco.org/'>AAFCO feeding trials</a>, and are for-profit companies that rely heavily on marketing and public perception to sell their product.</p>
    </div>
    <div className="fade">
      <h2>If your dog has been on a diet that may be associated with DCM, talk to your veterinarian about what tests may be appropriate to evaluate your dog’s heart.</h2>
    </div>
    <div className="fade">
      <p>Many dogs with DCM will not have an audible murmur when your veterinarian listens to their heart.  An echocardiogram, or ultrasound of your dog’s heart, is the best test to evaluate the heart’s structure and function.  If an echocardiogram is not available, blood testing or x-rays may be beneficial for some dogs.</p>
    </div>
    <div className="fade">
      <h2>Most veterinarians recommend switching away from diets that have been associated with DCM.</h2>
    </div>
    <div className="fade" style={{marginBottom: "20px"}}>
      <p>There are many opinions on what you should feed your dog, and your veterinarian is the best source of current information on this topic.  Hill’s, Purina, and Royal Canin are the three big names in pet food.  They have veterinary nutritionists on staff and actively research pet nutrition.  Some of the diets they make are prescription diets to help treat certain diseases.  Royal Canin is generally thought of as a high-quality food.  This food is manufactured by Mars, Inc., which also owns Iams and Eukaneuba, two grocery-store brands.  Purina and Hill’s are owned by Nestlé and Colgate-Palmolive respectively.  Both Hill’s and Purina make several lines or sub-brands of food across all quality tiers.</p>
    </div>
    <div className="fade">
      <p>Veterinarians and pet parents can report possible cases of diet-associated DCM to the FDA at <a target="_blank" rel="noopener noreferrer" href='https://www.safetyreporting.hhs.gov/SRP2/en/Home.aspx?sid=f0b26255-3fec-4947-b782-f7354b770f80'>this link</a>.</p>
    </div>
    </TopicWraper>
  </div>;
