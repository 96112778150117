import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";

import hyperthyroid1 from "../../images/hyperthyroid1.jpg"
import hyperthyroid2 from "../../images/hyperthyroid2.jpg"
import hyperthyroid3 from "../../images/hyperthyroid3.jpg"

export default () =>
  <div>
    <TopicWraper topic="Hyperthyroidism">
    <div className="fade">
      <p>Hyperthyroidism is the most common endocrine disease of cats.  Hyperthyroidism results from overgrowth of the small hormone-secreting thyroid glands located in the neck near the windpipe.  Cats over seven years of age should be screened with yearly blood testing that includes measurement of thyroxine (T4) hormone.</p>
    </div>
    <CarouselSingle header="What and Why">
      <div image={hyperthyroid1} boldtext="The cause of hyperthyroidism is not well understood in cats, but we do know it typically affects older cats." text="In most cases, the paired thyroid glands in your cat’s neck overgrow and produce too much of their hormones.  You may notice your cat is losing weight, is hungry all the time, or is hyperactive.  In some cases, cats with hyperthyroidism have soft stools, vomiting, or lethargy.  As the thyroid glands continue to overgrow, the hyperthyroidism usually worsens." />
    </CarouselSingle>
    <CarouselSingle header="At Your Veterinarian">
      <div image={hyperthyroid2} boldtext="Hyperthyroidism is often diagnosed at routine veterinary visits with annual blood screening in older cats." text="If your veterinarian suspects hyperthyroidism, they may suggest blood work that includes measurement of thyroxine (T4) hormone.  Your veterinarian may also suggest urinalysis to assess kidney function, as kidney disease is also quite common in older cats and often concurrent with hyperthyroidism." />
    </CarouselSingle>
    <CarouselSingle header="Treatment">
      <div image={hyperthyroid3} boldtext="Cats with hyperthyroidism can live long healthy lives with proper treatment." text="Long-term medical management with a drug called methimazole works well for many cats.  Methimazole can be administered twice daily as a pill, oral liquid, or topical gel applied to your cat’s ear.  For some indoor only cats with no housemates, a special diet (Hill’s y/d) with limited iodine can regulate their thyroid hormone levels.  Surgical removal of the overgrown thyroid gland may be an option for some cats but does carry risk.  A single treatment with radioactive iodine can cure your cat but can only be performed at a few facilities around the world.  Talk to your veterinarian about which treatment option is best for your cat." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
