import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons'
import { faCircle as faCircleSolid } from '@fortawesome/free-solid-svg-icons'

export default (props) => {
  let bullets = [];
  for (let i=0; i < props.num_slides; i++) {
    if (i===props.current_slide) {
      bullets.push([i, faCircleSolid])
    } else {
      bullets.push([i, faCircleRegular])
    }
  }
  const listBullets = bullets.map((e) => <li key={e[0]}><FontAwesomeIcon icon={e[1]} /></li>);

  const listParagraphs = props.paragraphs.map((p) => <p key={p["boldtext"]} className="para-space"><strong>{p["boldtext"]}</strong> {p["text"]}</p> );

  return(
    <div className={props.fader}>
      <div style={{position: "relative"}}>
        <img className="slide-img" src={props.image} alt="dog" />
        <button className="prev" onClick={props.prevFunc}>&#10094;</button>
        <button className="next" onClick={props.nextFunc}>&#10095;</button>
        <ul>
          {listBullets}
        </ul>
      </div>
      {listParagraphs}

    </div>
  )
}
