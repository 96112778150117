import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import ccl1 from "../../images/ccl1.jpg"
import ccl2 from "../../images/ccl2.jpg"
import ccl3 from "../../images/ccl3.jpg"
import ccl4 from "../../images/ccl4.jpg"
import ccl5 from "../../images/ccl5.jpg"
import ccl6 from "../../images/ccl6.jpg"
import ccl7 from "../../images/ccl7.jpg"
import ccl8 from "../../images/ccl8.jpg"
import ccl9 from "../../images/ccl9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Cranial Cruciate Ligament Injury">
    <div className="fade">
      <p>Cranial cruciate ligament (CCL) injury is a common condition that causes knee instability and pain. Without surgical treatment, CCL injury speeds the progression of osteoarthritis. If your dog has CCL injury, you may notice your dog is limping, has difficulty standing up, or has lost muscle mass in one or both hind limbs.</p>
    </div>
    <Carousel header="What and Why">
      <div image={ccl1} boldtext="CCL injury usually starts slow, as the cranial cruciate ligament in your dog’s knee wears down over months or years." text="Partial tears are common and may cause difficulty rising, intermittent limping, or sitting with a leg kicked out to the side. Some dogs may show no signs until the final threads of the ligament rupture. About half of the dogs that tear this ligament in one knee will also have problems with the other knee." />
      <div image={ccl2} boldtext="CCL injury affects dogs of all breeds, sizes, and ages." text="That said, Rottweilers and Labrador Retrievers are among the most commonly affected breeds, and CCL injury more frequently affects overweight dogs. This condition is less common in other breeds, such as Dachshunds, who more frequently have hind limb problems resulting from intervertebral disc disease (IVDD)." />
      <div image={ccl3} boldtext="The slope of your dog’s shin bone and other differences in anatomy make CCL injury different from ACL tears in humans." text="The ACL (anterior cruciate ligament) of humans is often compared to the CCL of dogs. The slope of the top of the shin bone (tibial plateau) of dogs makes the thigh bone (femur) more prone to sliding. This adds instability and impacts how we treat CCL injury in dogs." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={ccl4} boldtext="If your veterinarian suspects CCL injury, they will perform an orthopedic examination and may take x-rays of your dog’s knee." text="An orthopedic examination includes thorough palpation and manipulation of your dog’s joints. Part of this examination is often performed with your dog under sedation, as this allows your veterinarian to more thoroughly manipulate your dog’s joints." />
      <div image={ccl5} boldtext="CCL injury is one of the most common conditions that causes hind limb problems in dogs, but there are many other conditions that present with similar signs." text="Your veterinarian will perform a neurologic examination to rule out a nerve problem. Your veterinarian may perform joint taps, where a needle is used to sample fluid from several joints, to rule out infection or autoimmune disease." />
      <div image={ccl6} boldtext="To further investigate CCL injury, your veterinarian may need to look inside your dog’s knee joint with a small camera." text="This procedure, called arthroscopy, will let your veterinarian look directly at the ligaments and associated structures in your dog’s knee. It also allows your veterinarian to remove damaged structures inside the joint." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={ccl7} boldtext="Surgery is almost always recommended to treat CCL injury." text="There are a few different surgical options, and selecting the best option depends on your individual dog. The goals of surgery are to add stability to the knee joint and prevent acceleration of osteoarthritis. These surgeries do not completely stop osteoarthritis and cannot reverse osteoarthritic changes that have already occurred." />
      <div image={ccl8} boldtext="Tibial plateau leveling osteotomy (TPLO) and extracapsular suture stabilization (ex-cap) are the two most common surgical procedures used to treat CCL injury." text="TPLO involves cutting and rotating a piece of your dog’s shin bone to flatten the angle of the top of this bone and prevent the upper leg bone from sliding. An ex-cap involves adding fishing-line-like material just outside the joint capsule to replicate the function of the torn ligament." />
      <div image={ccl9} boldtext="Proper aftercare following surgery, including eight or more weeks of strict rest, is essential to ensure proper healing." text="Physical rehabilitation will speed recovery and improve function. Keeping your dog at a healthy weight and treating the inevitable osteoarthritis will also improve your dog’s long-term comfort." />
    </Carousel>
    </TopicWraper>
  </div>;
