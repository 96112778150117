import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";
import CarouselWithExtras from "../CarouselWithExtras"

import allergies1 from "../../images/allergies1.png"
import allergies2 from "../../images/allergies2.png"
import allergies3 from "../../images/allergies3.png"
import allergies4 from "../../images/allergies4.png"
import allergies5 from "../../images/allergies5.jpg"
import allergies6 from "../../images/allergies6.png"
import allergies7 from "../../images/allergies7.png"
import allergies8 from "../../images/allergies8.jpg"
import allergies9 from "../../images/allergies9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Canine Allergies">
    <div className="fade">
      <p>Itch is additive, and all the factors discussed below can contribute to
      your dog's overall level of itchiness. These are the steps your veterinarian
      will take to determine if your dog has allergies and the best options to
      help you relieve your dog's itch.</p>
      <h3 style={{marginBottom: "-30px"}}><span className="steps"><sup>No.</sup>1</span></h3>
    </div>
    <Carousel header="Rule Out Parasites & Infections">
      <div image={allergies1} boldtext="Parasites and infections are the most common causes of itchy dog." text="Your veterinarian will look for bacteria and parasites on your dog's skin and may prescribe a medication to ensure your dog is not itchy from mites, fleas, or an existing skin infection.  Keeping your dog on good flea preventative medication year-round is an important part of controlling allergies in your four-legged family member." />
      <div image={allergies2} boldtext="Skin infections can contribute to your dog's itchiness and make it harder to treat and prevent the itchiness associated with your dog's allergies." text="Your veterinarian will take samples from your dog's skin using tape, a glass slide, or a metal scraper.  Your veterinarian will look for bacteria, yeast, and other infectious agents." />
      <div image={allergies3} boldtext="Bites from fleas and mites are a common cause of allergies in dogs." text="Even if your dog is on flea control, your veterinarian will suggest that you use an adulticidal product that also protects your dog against mites.  All your pets, including all cats, must be on adulticidal flea preventative to ensure your allergic dog is not exposed to a single flea.  Your veterinarian will closely examine your dog's skin for signs of fleas and may perform a skin scraping to look for mites." />
    </Carousel>
    <h3 style={{marginBottom: "-30px", marginTop: "-30px"}}><span className="steps"><sup>No.</sup>2</span></h3>
    <Carousel header="Rule Out Food Allergies">
      <div image={allergies4} boldtext="Although less common than environmental allergies, it is important for your veterinarian to rule out the possibility of food allergies in your dog." text="Food allergies are a lot easier to treat than environmental allergies because you can control what your dog eats and prevent exposure to foods that make your dog itchy.  Your veterinarian will instruct you how to perform a diet trial if he or she suspects that there may be a food component to your dog's allergies." />
      <div image={allergies5} boldtext="Beef and chicken are two of the most common food allergens, but it is important for your veterinarian to look at the ingredients in your current dog food." text="If your veterinarian prescribes a diet trial, they will avoid foods with similar protein sources to your dog's current diet. This is because your dog may have become sensitized to antigens from that protein source." />
      <div image={allergies6} boldtext="A diet trial for an allergic dog is different from other veterinary diet trials." text="This diet trial must be very strict; so, you cannot allow your dog to get any table snacks, unapproved treats, or flavored medications. An allergy diet trial will last six to eight weeks. Your veterinarian can explain an allergy diet trial in greater detail." />
    </Carousel>
    <h3 style={{marginBottom: "-30px", marginTop: "-30px"}}><span className="steps"><sup>No.</sup>3</span></h3>
    <CarouselWithExtras header="Assess Environmental Allergens">
      <div image={allergies7} paragraphs={[{boldtext: "If your dog suffers from itchiness that may have started out seasonally but is now year-round, environmental allergens may be a significant component of your dog's allergies.", text:"Environmental allergies can be difficult to treat and manage.  If you have a relatively young dog that is itchy more than six months out of the year, your dog may benefit from allergy testing and allergen-specific immunotherapy.  If your dog's itchiness is limited to less than six months, your veterinarian may prescribe anti-itch medications, steroids, or immunosuppressants.  These therapies, along with medicated baths two to three times per week, will help control some of your dog's itch, but may not eliminate all the allergic flare-ups your dog experiences."}]} />
      <div image={allergies8}  paragraphs={[{boldtext: "Allergy testing is not a diagnostic to confirm that your dog has environmental allergies; it is the first step in allergen-specific immunotherapy which can help train your dog's immune system to act more appropriately towards environmental allergens.", text:"Allergy testing is not appropriate for all allergic dogs.  Once your veterinarian has determined that your dog is a candidate for allergy testing, they will recommend either allergen-specific IgE serum testing or intradermal testing. Either of these tests can help your veterinarian formulate and prescribe allergen-specific immunotherapy to retrain your dog's immune system."}, {boldtext: "IgE serum testing:", text: "This test requires a blood sample which will be sent to a lab to look for reactivity to grass pollens, tree pollens, weed pollens, environmental fungi, and other allergens."}, {boldtext: "Intradermal testing:", text: "This test is performed at the veterinary hospital with your dog under light sedation. A veterinarian will shave a 5-inch by 8-inch area on the side of your dog and inject a very small amount of various common environmental antigens into about 50 tiny spots in the skin of your dog.  Your veterinarian will then read the skin's reaction to these allergens by feeling these spots at 15 and 30 minutes post-injection."}]} />
      <div image={allergies9}  paragraphs={[{boldtext: "Allergen-specific immunotherapy can help reduce your dog's overreaction to environmental allergens.", text:"It can take up to 12 months to see the benefits of allergen-specific immunotherapy, but this therapy reduces the need for and helps avoid the side effects of immunosuppressive drugs.  Two types of allergen-specific immunotherapy are available, and your veterinarian can help you pick the option that works best for you and your dog."}, {boldtext: "Subcutaneous immunotherapy:", text: "This option involves giving your dog shots just under the skin and can eventually be performed weekly by pet parents at home."}, {boldtext: "Sublingual immunotherapy:", text: "This option involves giving your dog twice-daily squirts of oral medication."}]} />
    </CarouselWithExtras>
    </TopicWraper>
  </div>;
