import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import diabetes1 from "../../images/diabetes1.png"
import diabetes2 from "../../images/diabetes2.png"
import diabetes3 from "../../images/diabetes3.png"
import diabetes4 from "../../images/diabetes4.png"
import diabetes5 from "../../images/diabetes5.png"
import diabetes6 from "../../images/diabetes6.png"
import diabetes7 from "../../images/diabetes7.png"
import diabetes8 from "../../images/diabetes8.png"
import diabetes9 from "../../images/diabetes9.png"

export default () =>
  <div>
    <TopicWraper topic="Canine Diabetes">
    <div className="fade">
      <p>Dogs with diabetes may initially appear happy and healthy. If your dog has diabetes, the first thing you may notice is that your dog is drinking more water and urinating more. Your dog may appear hungry all the time, but this is not always the case. You may not even realize your dog has diabetes until your veterinarian finds signs of diabetes at your dog's yearly checkup.</p>
    </div>
    <Carousel header="What and Why">
      <div image={diabetes1} boldtext="Dogs with diabetes produce little to no insulin, a molecule that is responsible for maintaining blood glucose levels." text="Canine diabetes resembles human type 1 diabetes and requires lifelong treatment with insulin. Middle-aged to older female dogs are most at risk for developing diabetes, but we frequently see it in other populations of dogs as well." />
      <div image={diabetes2} boldtext="We don’t fully understand why dogs get diabetes, but a combination of genetic and environmental factors is likely at play." text="Canine diabetes is usually due to an insulin deficiency, not insulin resistance, so it is unlikely that we can blame a dog’s diet and exercise habits for the onset of diabetes." />
      <div image={diabetes3} boldtext="Diabetes often accompanies other diseases." text="These include pancreatitis, or inflammation of the pancreas. This is a good reason to avoid feeding your dog table scraps that are high in fat, as high-fat human foods can anger your dog’s pancreas. Diabetes may develop shortly after an intact female dog goes through her heat cycle. If you do have an intact female dog that is diagnosed with diabetes, it is possible that spaying her immediately will correct the diabetes. Cushing’s syndrome and hypothyroidism can also contribute to the onset of canine diabetes." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={diabetes4} boldtext="If your veterinarian thinks your dog may have diabetes, they will do a blood test to measure your dog’s blood glucose level." text="Your veterinarian will also do a urine test to identify glucose in your dog’s urine, which is not present in normal dogs. If your dog has persistently increased blood glucose levels, and glucose spills over into the urine, then your dog has diabetes." />
      <div image={diabetes5} boldtext="Your veterinarian will also need to perform a urine culture to ensure your dog does not have a urinary tract infection." text="Dogs with diabetes often have urinary tract infections which require additional treatment. An untreated urinary tract infection can make your dog feel sick and progress to more serious disease." />
      <div image={diabetes6} boldtext="Your veterinarian will likely perform additional blood tests looking for concurrent diseases." text="Pancreatitis, Cushing’s disease, and hypothyroidism can all contribute to and complicate the treatment of your dog’s diabetes. Your veterinarian may perform an abdominal ultrasound to help rule out these and other diseases." />
    </Carousel>
    <Carousel header="What to expect">
      <div image={diabetes7} boldtext="Dogs with diabetes require lifelong treatment." text="This almost always means you will have to give your dog insulin injections twice daily. Most dog parents become quite comfortable with this routine. At home blood glucose monitoring is possible, but shortly after your dog is diagnosed with diabetes, your four-legged family member may require several trips to the veterinarian for glucose curve measurements. This is necessary as your veterinarian adjusts and fine tunes your dog’s dose of insulin. Dogs with diabetes often develop cataracts and become blind, so pet parents should be prepared for this." />
      <div image={diabetes8} boldtext="Consistency in diet, insulin administration, and exercise is key." text="Diabetic dogs should be fed twice daily, morning and evening, with 12 hours between meals. You should give your dog’s insulin injection immediately after these meals to help regulate the increased blood glucose that follows digestion of food. Changes in your dog’s daily exercise habits, such as strenuous weekend exercise, are not recommended as this will affect your dog’s energy consumption and cause fluctuations in blood glucose, complicating blood sugar regulation." />
      <div image={diabetes9} boldtext="Watch out for signs that your dog’s blood sugar level is too low." text="Hypoglycemia, or low blood sugar, can be quite dangerous. If you notice that your diabetic dog is acting strange, dizzy, or especially tired, your four-legged family member may have low blood sugar. If your dog becomes unconscious or has seizures, he or she may be severely hypoglycemic. If your diabetic dog is conscious and able to eat, try feeding small quantities of food and check in with your veterinarian immediately." />
    </Carousel>
    </TopicWraper>
  </div>;
