import React from 'react';
import { Link } from "react-router-dom";
import paperBackground from "../images/backgroundSquare.png";

var styles = {
  card: {
    background: `url(${paperBackground})`,
  },
  cardImg: {
    maxHeight: '100px',
    width: 'auto',
    paddingTop: '20px'
  }
};

export default (props) =>
  <div>
    <Link to={props.path}><div className="card" style={styles.card}>
      <img style={styles.cardImg} src={props.cardimg} alt="dog" />
      <h2>{props.label}</h2>
    </div></Link>
  </div>;
