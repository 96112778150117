import React, {Component} from 'react';
import TopicWraper from "../TopicWraper";
import fireworks from "./Fireworks.mp3";
import thunder from "./Thunder.mp3";

class Fireworks extends Component {
  state = {
    playFireworks: false,
    playThunder: false
  }
  fireworksAudio = new Audio(fireworks)
  thunderAudio = new Audio(thunder)

  togglePlayFireworks = () => {
    this.setState({ playFireworks: !this.state.playFireworks}, () => {
      this.state.playFireworks? this.fireworksAudio.play() : this.fireworksAudio.pause();
    });
  }
  togglePlayThunder = () => {
    this.setState({ playThunder: !this.state.playThunder}, () => {
      this.state.playThunder? this.thunderAudio.play() : this.thunderAudio.pause();
    });
  }

  render() {
    return(
      <div>
        <TopicWraper topic="Firework and Thunderstorm Anxiety">
        <div className="fade">
          <p>The intensity of and irregular interval between the noises can lead some dogs to become fearful during fireworks or thunderstorms.  The following program can be used to reduce fear and anxiety during these events.  The goal of this program is for your dog to remain relaxed during fireworks or thunderstorms.</p>
        </div>
        <div className="fade">
          <h2>Set your dog up for success.</h2>
        </div>
        <div className="fade" style={{marginBottom: "20px"}}>
          <p>Dogs with profound anxiety that are unable to settle during the early stages of this program may benefit from  calming pheromones, behavioral supplements, or anti-anxiety medications from your veterinarian.</p>
        </div>
        <div className="fade">
          <p>It may be helpful to teach your dog the ‘settle down’ trick or use a training mat that your dog can ‘go to’.  This training will progress most rapidly in a space where your dog is most comfortable.</p>
        </div>
        <div className="fade">
          <h2>Help your dog choose calmness.</h2>
        </div>
        <div className="fade" style={{marginBottom: "20px"}}>
          <p>Use the sound clips below at low volumes to habituate your dog and reduce reactivity.  These sounds should not be played at a high enough volume to prompt any anxiety in your dog during his or her calm state.  As a pet parent, these sound clips should not change your behavior or what you are doing.</p>
        </div>
        <div className="fade">
          <p>Encourage your dog’s state of calmness by interacting with them or offering a favorite toy without drawing attention to the sound stimulus.</p>
        </div>
        <div>
          <button className={"soundButton"} onClick={this.togglePlayFireworks}>{this.state.playFireworks? 'Pause Fireworks' : 'Play Fireworks'}</button>
          <button className={"soundButton"} onClick={this.togglePlayThunder}>{this.state.playThunder? 'Pause Thunder' : 'Play Thunder'}</button>
        </div>
        <div className="fade">
          <h2>Your goal is to maintain your dog's calm state.</h2>
        </div>
        <div className="fade" style={{marginBottom: "20px"}}>
          <p>Signs of low-level anxiety include nose licking, yawning and blinking the eyes more frequently.  If you notice any of these signs, take a break from the exercise, and reduce the volume during the next training session.</p>
        </div>
        <div className="fade">
          <p>More frequent training sessions of short duration are more effective than longer, less-frequent sessions.  Gradually increase the volume or length of training sessions at a rate that your pet can accept without causing any anxiety.  Eliminating physiologic anxiety will take longer than eliminating behavioral signs of anxiety, so make sure to repeat training sessions many times without failure before progressing.</p>
        </div>
        </TopicWraper>
      </div>
    );
  }
}


export default Fireworks
