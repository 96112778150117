import React, {Component} from 'react';
import LabLogo from "./LabLogo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import HandoutButton from "./HandoutButton"
import Footer from "./Footer"
import addisons from "../images/addisons-icon.png";
import aki from "../images/aki-icon.png";

import allergies from "../images/allergies-icon.png";
import anesthesia from "../images/anesthesia-icon.png";
import cancer from "../images/cancer-icon.png";
import ulcers from "../images/ulcers-icon.png";
import crcld from "../images/crcld-icon.png";
import ckd from "../images/ckd-icon.png";

import cushings from "../images/cushings-icon.png";
import dcm from "../images/dcm-icon.png";
import dietdcm from "../images/diet-dcm-icon.png";
import diabetes from "../images/diabetes-icon.png";
import ear from "../images/ear-icon.png";
import bite from "../images/bite-icon.png";
import fireworks from "../images/fireworks-icon.png";
import gastropexy from "../images/gastropexy-icon.png";
import hipdysplasia from "../images/chd-icon.png";
import hypothyroid from "../images/hypothyroid-icon.png";
import shunts from "../images/shunts-icon.png";
import oa from "../images/oa-icon.png";
import parvo from "../images/parvovirus-icon.png";
import seizures from "../images/seizures-icon.png";
import vhd from "../images/vhd-icon.png";

import hyperthyroidism from "../images/hyperthyroid-icon.png"

const searchLabelsPathsImagesTerms = [
  ["Addison's Disease", "/addisons-disease", addisons, "addisons disease addison's disease"],
  ["Acute Kidney Injury", "/aki", aki, "acute kidney injury"],

  ["Allergies", "/canine-allergies", allergies, "canine allergies allergy"],
  ["Anesthesia", "/anesthesia", anesthesia, "anesthesia"],
  ["Cancer", "/cancer", cancer, "cancer"],
  ["Corneal Ulcers", "/corneal-ulcers", ulcers, "corneal ulcers eye"],
  ["Chronic Kidney Disease", "/ckd", ckd, "chronic kidney disease"],

  ["Cranial Cruciate Ligament Injury", "/ccl", crcld, "cranial cruciate ligament injury crcld"],
  ["Cushing's Syndrome", "/cushings-syndrome", cushings, "cushing's syndrome cushings syndrome"],
  ["Diabetes", "/diabetes", diabetes, "diabetes"],
  ["Dilated Cardiomyopathy (DCM)", "/dcm", dcm, "dilated cardiomyopathy (dcm)"],
  ["Diet-associated Dilated Cardiomyopathy", "/diet-associated-DCM", dietdcm, "diet-associated dilated cardiomyopathy dcm diet associated"],
  ["Dog Bite Wounds", "/bite-wounds", bite, "dog bite wounds dog bites"],
  ["Ear Infections", "/ear-infections", ear, "ear infections ears"],
  ["Firework and Thunderstorm Anxiety", "/fireworks", fireworks, "firework and thunderstorm anxiety"],
  ["Gastropexy", "/gastropexy", gastropexy, "gastropexy"],
  ["Hip Dysplasia", "/hip-dysplasia", hipdysplasia, "hip dysplasia"],
  ["Hyperthyroidism", "/hyperthyroidism", hyperthyroidism, "hyperthyroidism"],

  ["Hypothyroidism", "/hypothyroidism", hypothyroid, "hypothyroidism"],
  ["Liver Shunts", "/liver-shunts", shunts, "liver shunts"],
  ["Osteoarthritis", "/osteoarthritis", oa, "osteoarthritis oa"],
  ["Parvovirus", "/parvovirus", parvo, "parvovirus parvo virus"],
  ["Seizures", "/seizures", seizures, "seizures"],
  ["Valvular Heart Disease", "/vhd", vhd, "valvular heart disease vhd"]
]

class AllHandouts extends Component {

  constructor(props) {
    super(props);
    this.state = {
      query: "",
      filteredRoutes: searchLabelsPathsImagesTerms
    }
  }

  filterRoutes = (q) => {
    let filtered = searchLabelsPathsImagesTerms.filter(r => r[3].toLowerCase().includes(q.toLowerCase()));
    this.setState({
      filteredRoutes: filtered
    })
  }

  termSearch = (e) => {
    this.setState({
      query: e.target.value
    })
    this.filterRoutes(e.target.value)
  }

  componentDidMount() {
    document.title = "Pet Parent Handouts"
  };

  render() {
    return (
      <div className="container">
        <LabLogo />
        <h1 className="main-header">Pet Parent Handouts</h1>
        <FontAwesomeIcon style={{fontSize: "1.3rem"}} icon={faSearch} />
        <input
            style={{backgroundColor: "#fdf8f4", fontFamily: "Spectral, Verdana, sans-serif", border: "1px solid #414344", boxShadow: "-2px 0px 3px 1px #414344", borderRadius: "2px", marginLeft: "10px", paddingLeft: "5px", fontSize: "1.3rem"}}
            onChange={ this.termSearch }
            type='text'
            role='form'
            aria-labelledby='search'
            tabIndex="0"
            placeholder=""
            value={ this.state.query }
          />

        {this.state.filteredRoutes.map( r =>
          <HandoutButton label={r[0]} path={r[1]} cardimg={r[2]} key={r[1]}/>
        )}
        <Footer />
      </div>
    )
  };
};

export default AllHandouts;
