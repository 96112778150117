import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import vhd1 from "../../images/vhd1.jpg"
import vhd2 from "../../images/vhd2.jpg"
import vhd3 from "../../images/vhd3.jpg"
import vhd4 from "../../images/ccl3.jpg"
import vhd5 from "../../images/vhd5.jpg"
import vhd6 from "../../images/vhd6.jpg"
import vhd7 from "../../images/vhd7.jpg"
import vhd8 from "../../images/vhd8.jpg"
import vhd9 from "../../images/vhd9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Valvular Heart Disease">
    <div className="fade">
      <p>Valvular heart disease is the most common acquired heart disease seen in dogs.  A leaky heart valve is often heard as a heart murmur during your dog’s routine veterinary exam.  Valvular heart disease usually worsens over time, but some dogs with valvular heart disease live well into old age with no treatment.  In some severe cases, valvular heart disease can cause congestive heart failure or pulmonary hypertension.  Your veterinarian’s goal will be to appropriately monitor and treat your dog to delay the onset of heart failure.</p>
    </div>
    <Carousel header="What and Why">
      <div image={vhd1} boldtext="Valvular heart disease is a common condition that is often seen in older small-breed dogs." text="It can be seen in dogs of all ages and sizes.  We are not entirely sure what causes the heart valves to become leaky, and a mix of genetic and environmental factors are likely involved.  Certain breeds, including the King Charles Cavalier Spaniel, are at much greater risk of valvular heart disease." />
      <div image={vhd2} boldtext="The mitral valve between the left atrium and left ventricle, two of the four chambers of your dog’s heart, is most commonly affected by valvular heart disease." text="When this valve leaks, blood that should be pumped forward from your dog’s heart to the rest of the body will leak backwards into the left atrium.  This causes the left atrium to get bigger and eventually leads to congestive heart failure." />
      <div image={vhd3} boldtext="Coughing, rapid breathing, or exercise intolerance may be the first things you notice if your dog has valvular heart disease." text="These signs are nonspecific and can be seen with numerous other conditions. Further investigation by your veterinarian is necessary to determine the cause of these symptoms in your dog." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={vhd4} boldtext="Valvular heart disease is often uncovered at routine veterinary visits when your veterinarian listens to your dog’s heart." text="A leaky heart valve can sometimes be heard as a heart murmur, and the location of this murmur may indicate which heart valve is affected.  In cases of valvular heart disease, louder murmurs often indicate more severe disease." />
      <div image={vhd5} boldtext="Your veterinarian will perform other tests to further investigate the severity of disease in your dog." text="These tests may include chest x-rays, blood pressure, blood work including special heart-related markers, and electrocardiography (ECG).  Chest x-rays are often used to evaluate the size of your dog’s heart, which has important implications in disease severity.  High blood pressure can accelerate valvular heart disease." />
      <div image={vhd6} boldtext="An echocardiogram, often abbreviated echo, is the best way for your veterinarian to evaluate the structure and function of your dog’s heart and heart valves." text="This test is usually performed by a veterinary cardiologist, a specialist with additional training and experience in cardiology.  An echocardiogram is an ultrasound of the heart and is the only way to visualize a leaky heart valve." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={vhd7} boldtext="Your veterinarian will individualize your dog’s monitoring and treatment based on the changes to your dog’s heart." text="Dogs with mild disease and minimal heart changes may not need medical treatment.  Once the heart changes have progressed passed a certain point, your dog may benefit from a medication, pimobendan, that improves heart function and relaxes blood vessels." />
      <div image={vhd8} boldtext="Valvular heart disease is a chronic progressive condition." text="As the leak worsens, dogs are more likely to develop congestive heart failure.  As this point approaches, your dog may benefit from additional medications.  If your dog has increased coughing, difficulty breathing, exercise intolerance, or starts falling over, he or she may be in heart failure and should see your veterinarian immediately." />
      <div image={vhd9} boldtext="Dogs with valvular heart disease should be re-evaluated by your veterinarian about every six months to monitor disease progression." text="At these visits your veterinarian will likely take x-rays to look for changes in heart size.  Some dogs with enlarged hearts may benefit from a special cardiac diet.  All should avoid high salt treats. Safe treats include veggies and small pieces of boiled chicken." />
    </Carousel>
    </TopicWraper>
  </div>;
