import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import parvo1 from "../../images/parvo1.png"
import parvo2 from "../../images/parvo2.png"
import parvo3 from "../../images/parvo3.png"
import parvo4 from "../../images/parvo4.png"
import parvo5 from "../../images/parvo5.png"
import parvo6 from "../../images/parvo6.png"

export default () =>
  <div>
    <TopicWraper topic="Parvovirus">
    <div className="fade">
      <p>Canine Parvovirus is typically seen in young puppies and causes vomiting, diarrhea, and a depressed attitude. It attacks the cells of the gastrointestinal tract and prevents your dog from properly absorbing water and nutrients. Early diagnosis and treatment by your veterinarian are essential, as untreated Parvovirus infections are often fatal.</p>
    </div>
    <Carousel header="Prevention">
      <div image={parvo1} boldtext="Puppies under 6 months of age are most at risk of Parvovirus infection." text="These young puppies have not fully developed their own immune systems. The Parvovirus vaccination is considered a core vaccine. It should be administered starting at 6 to 8 weeks and then boostered every 3 to 4 weeks until your puppy is 14 to 16 weeks old. Like all vaccinations, it cannot completely eliminate the risk of infection." />
      <div image={parvo2} boldtext="You should avoid taking your puppy to high risk areas before it has completed its full course of Parvovirus vaccines." text="Parvovirus is extremely stable in the environment, so it is usually found in all areas where other puppies hang out—dog parks, doggie daycare, pet stores, and anywhere else young dogs congregate." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={parvo3} boldtext="Diagnosis of Parvovirus is relatively simple and can be performed at most veterinary clinics with a 15-minute test." text="This test is very accurate. In rare cases, it will be too early in the disease process to detect the virus. If your dog is having signs that suggest Parvovirus, but the test is negative, your veterinarian may want to repeat the test in 1 to 2 days." />
      <div image={parvo4} boldtext="Treatment of Parvovirus includes supportive care with IV fluid therapy, antibiotics, and anti-nausea medication." text="Aggressive treatment is necessary to prevent dehydration and death. With hospitalization and supportive care, puppies usually recover from this disease. Left untreated, the outcome is seldom good." />
    </Carousel>
    <Carousel header="Decontamination">
      <div image={parvo5} boldtext="Decontamination of your home and yard are important before you bring a new puppy into a home that has been exposed to Parvovirus." text="Parvovirus will last months in the environment. Freezing temperatures can protect the virus in outdoor environments and help it last even longer. Bleach will kill the virus and can be used to decontaminate indoor areas, toys, bowls, and bedding. Bleach is less practical for carpeted areas, fabric, and lawns, which may harbor the virus for months. With time, the virus particles should no longer be infectious." />
      <div image={parvo6} boldtext="Puppies that recover from parvovirus will continue to shed the virus in their feces, so you should keep them away from other young dogs for the next couple months." text="You should also clean up their poop to prevent other young dogs from becoming exposed. Thankfully, most adult dogs are immune to the virus." />
    </Carousel>
    </TopicWraper>
  </div>;
