import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import shunts1 from "../../images/shunts1.jpg"
import shunts2 from "../../images/shunts2.jpg"
import shunts3 from "../../images/shunts3.jpg"
import shunts4 from "../../images/shunts4.jpg"
import shunts5 from "../../images/shunts5.jpg"
import shunts6 from "../../images/shunts6.jpg"
import shunts7 from "../../images/shunts7.jpg"
import shunts8 from "../../images/shunts8.jpg"
import shunts9 from "../../images/shunts9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Liver Shunts">
    <div className="fade">
      <p>A liver shunt, or portosystemic shunt (PSS), is an abnormal blood vessel that allows blood to bypass the liver.  This can cause symptoms ranging from vomiting and diarrhea to neurologic dysfunction and seizures.  Some dogs are born with liver shunts, and some of these can be corrected with surgery.  Other liver shunts are the result of abnormal liver function.</p>
    </div>
    <Carousel header="What and Why">
      <div image={shunts1} boldtext="Dogs born with liver shunts may be smaller than their littermates, display abnormal behavior, or take a long time to recover following anesthesia." text="Their abnormal behavior, difficulty walking, or seizures may occur more frequently after a meal.  These shunts are more common in certain breeds, including Yorkshire Terriers, Miniature Schnauzers, Labrador Retrievers, and Golden Retrievers." />
      <div image={shunts2} boldtext="Other dogs acquire liver shunts because there is a problem with the liver preventing normal blood flow." text="These shunts, although an indication of disease, are protecting the liver and other organs from complete destruction.  These shunts cannot be fixed with surgery." />
      <div image={shunts3} boldtext="The liver is important because it takes toxins out of the blood." text="When blood bypasses the liver excess toxins build up.  Some of these toxins are the result of normal digestion.  These toxins contribute to a range of symptoms from vomiting and diarrhea, to difficulty walking, blindness, and seizures." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={shunts4} boldtext="Blood work, including special tests for ammonia and bile acids, urinalysis, x-rays, and ultrasound are tests that your veterinarian may perform if they suspect a liver shunt." text="Your veterinarian will characterize the liver shunt to direct treatment options.  Advanced imaging with CT is often required to characterize the shunt and allow for surgical planning." />
      <div image={shunts5} boldtext="Some liver shunts can be identified by ultrasound, but CT imaging is usually necessary before surgery." text="Some shunts are not easily seen, even with more advanced imaging.  This depends on the location of the shunt, inside or outside the liver, and the number of shunts, one or many." />
      <div image={shunts6} boldtext="Sometimes liver shunts present with life-threatening symptoms." text="Your veterinarian will need to stabilize your dog before the shunt itself can be addressed.  Life threatening conditions resulting from liver shunts include seizures, urinary tract obstruction, and severe dehydration." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={shunts7} boldtext="Dietary modification, antibiotics, and lactulose are used to prevent toxin buildup." text="The toxin of greatest concern is ammonia.  A diet rich in carbohydrates and low in protein will help prevent ammonia production.  Certain antibiotics will reduce ammonia-producing bacteria in your dog’s gut.  Lactulose will bind ammonia and speed its passage out of your dog." />
      <div image={shunts8} boldtext="When possible, surgery is the best treatment option." text="In these dogs, preventing the abnormal bypass of blood around the liver may eventually restore liver function.  These shunts are usually closed with special devices that work slowly over weeks to months.  Some shunts take multiple surgeries to close." />
      <div image={shunts9} boldtext="Medical treatment aimed at reducing blood toxins must be continued even after surgical correction of a liver shunt." text="The liver needs time to rebuild and adapt.  Many dogs live long, happy lives after surgical correction of their liver shunt.  Some dogs face serious complications following surgery which can include seizures, low blood sugar, or recurrence of clinical signs." />
    </Carousel>
    </TopicWraper>
  </div>;
