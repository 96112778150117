import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";

import ckd1 from "../../images/ckd1.jpg"
import ckd2 from "../../images/ckd2.jpg"
import ckd3 from "../../images/ckd3.jpg"

export default () =>
  <div>
  <TopicWraper topic="Chronic Kidney Disease">
  <div className="fade">
    <p>The kidneys are important for filtering waste products from the blood and excreting them in the urine.  They also play a role in electrolyte balance, blood pressure, blood volume, and making the signal for red blood cell production.  Monitoring blood values related to kidney health is an important part of wellness blood work and urine testing at routine veterinary visits.</p>
  </div>
  <CarouselSingle header="What and Why">
    <div image={ckd1} boldtext="In cats with chronic kidney disease (CKD), the kidneys are not doing their job properly." text="Often, the functional unit of the kidney, the nephron, has been replaced by fibrous scar tissue.  This  means the damage is irreversible, but many cats with CKD will live for years with proper management.  Kidney disease can affect cats of all ages but is often a disease of older cats.  Certain breeds, including Persians and Himalayans, are more at risk.  You may notice your cat is losing weight or muscle mass, losing appetite or vomiting, or acting more lethargic. Often, by the time chronic kidney disease is identified, the cause cannot be found." />
  </CarouselSingle>
  <CarouselSingle header="At Your Veterinarian">
    <div image={ckd2} boldtext="Blood and urine testing at your veterinarian can help identify a kidney problem." text="Blood work and urinalysis may be recommended at your cat’s wellness visit. Yearly blood work and urine testing is especially important in cats over 7 years of age to monitor kidney values.  Poorly concentrated urine, protein in the urine, or increased kidney values on your cat’s blood work may suggest the kidneys are not doing their job.  Further testing often includes blood pressure measurement, abdominal x-rays or ultrasound, specific infectious disease testing, urine culture, and measurement of blood thyroid hormone.  Correcting any dehydration or acute kidney injury is necessary to evaluate the severity of your cat’s chronic kidney disease." />
  </CarouselSingle>
  <CarouselSingle header="Treatment">
    <div image={ckd3} boldtext="Management of chronic kidney disease is individualized to the patient, the cause, and the severity of disease." text="Many of these treatments are geared towards managing the consequences of inadequate kidney function.  In early stages, encouraging your cat to drink more water by offering wet food, additional water bowls, and water fountains may be all that is needed.  Some cats with CKD will need fluids given under the skin on a regular basis, and some pet parents are comfortable doing this at home.  Prescription diets made for cats with kidney disease may be suggested and are generally lower in protein and phosphorus than regular cat food.  In some cases, a phosphorus binder, aluminum hydroxide, may be added to the food to reduce phosphorus uptake.  Medications may be used to help reduce high blood pressure and increase red blood cell production. For some cats, anti-nausea medications and appetite stimulants may be helpful." />
  </CarouselSingle>
  <div className="fade" style={{paddingBottom: "20px"}}>
    <p>Dialysis—often a treatment for humans with reduced kidney function—is available at certain specialty centers.  Kidney transplants are offered by a few veterinary hospitals and may be appropriate for some patients.  Your veterinarian is the best source of information for other treatment options specific to your cat.</p>
  </div>
  </TopicWraper>
  </div>;
