import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";


import dogBite1 from "../../images/dogBite1.png"
import dogBite2 from "../../images/dogBite2.png"
import dogBite3 from "../../images/dogBite3.png"

export default () =>
  <div>
    <TopicWraper topic="Dog Bite Wounds">
    <div className="fade">
      <p>Dogs often require emergency medical attention when they are attacked by another dog and sustain bite wounds. The extent of the damage from a dog bite may not be immediately obvious, so you should have your dog evaluated by a veterinarian immediately.</p>
    </div>
    <CarouselSingle header="Prevention">
      <div image={dogBite1} boldtext="Preventing inter-dog aggression is an important part of avoiding dog bite wounds." text="Early socialization of young puppies is necessary as they learn the boundary between play and aggression. A desirable toy, food bowl, or dog bed can spark a fight between otherwise-friendly dogs. A dog that is in pain or afraid may be more likely to bite. If you see signs that suggest a dog may become aggressive, it is important to remove your dog from the situation. Signs of aggression may include showing teeth, growling, pinning the ears back or holding them straight up, and appearing tense or rigid. Some dogs give no warning and show no signs before lashing out." />
    </CarouselSingle>
    <CarouselSingle header="At your veterinarian">
      <div image={dogBite2} boldtext="Depending on the size and location of the wounds, your veterinarian may need to take radiographs (X-rays), ultrasound, or surgically explore the wounds with your dog heavily sedated or anesthetized." text="If the wounds are near the chest, it is important to rule out the possibility that air has entered the chest cavity. If the wounds are near your dog’s abdomen, your veterinarian will look for signs of air, blood, or other fluid in your dog’s belly. Surgical exploration of the wounds while your dog is under heavy sedation or anesthesia is often necessary to understand the extent of the wounds and prepare for treatment and wound management. A puncture wound that appears quite small on the surface may have caused significant damage under the skin." />
    </CarouselSingle>
    <CarouselSingle header="Treatment">
      <div image={dogBite3} boldtext="Treating dog bite wounds with proper surgical repair, bandaging, and medications is important to prevent further complications." text="Antibiotics will help prevent infection, and several pain medications may be used to keep your dog comfortable. Surgical repair of each wound depends on the individual injury. Wounds often require removal of devitalized tissue that has lost its blood supply or is too damaged to heal. Some wounds require stitches, while others will be left open for proper drainage. In most cases, it is necessary to re-evaluate the wound and change bandages daily, especially in the early stages of treatment." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
