import React, { Component } from 'react';
import CarouselItemWithExtras from "./CarouselItemWithExtras";

class CarouselWithExtras extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_slide: 0,
      num_slides: this.props.children.length,
      fader: "fade"
    }
  };

  componentDidMount() {
    setTimeout(this.unfade, 1000)
  }

  unfade = () => {
    this.setState({
      fader: "no-fade"
    })
  }

  onPlus = () => {
    if (this.state.current_slide < this.state.num_slides-1) {
      this.setState({
        current_slide: this.state.current_slide+1
      });
    } else {
      this.setState({
        current_slide: 0
      });
    }
    this.setState({
      fader: "fade"
    })
    setTimeout(this.unfade, 1000)
  };

  onMinus = () => {
    if (this.state.current_slide > 0) {
      this.setState({
        current_slide: this.state.current_slide-1
      });
    } else {
      this.setState({
        current_slide: this.state.num_slides-1
      });
    }
    this.setState({
      fader: "fade"
    })
    setTimeout(this.unfade, 1000)
  }

  render () {
    return (
      <div>
        <h3>{this.props.header}</h3>
        <CarouselItemWithExtras nextFunc={this.onPlus} current_slide={this.state.current_slide} num_slides={this.state.num_slides} prevFunc={this.onMinus} fader={this.state.fader} image={this.props.children[this.state.current_slide].props.image} paragraphs={this.props.children[this.state.current_slide].props.paragraphs} />
        <div className="line-break"></div>
      </div>
    );
  }
}
export default CarouselWithExtras;
