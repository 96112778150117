import React, {Component} from 'react';
import { Link } from "react-router-dom";
import LabLogo from "./LabLogo"
import Footer from "./Footer"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-regular-svg-icons'

class TopicWraper extends Component {
  componentDidMount() {
    document.title = this.props.topic
  };

  render() {
    return (
      <div className="container">
        <LabLogo />
        <h1 className="main-header">{this.props.topic}</h1>
        {this.props.children}
        <Link to="/">
          <FontAwesomeIcon className="back-button" icon={faArrowAltCircleLeft} />
        </Link>
        <Footer />
      </div>
    )
  };
};

export default TopicWraper;
