import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import seizures1 from "../../images/seizures1.png"
import seizures2 from "../../images/seizures2.png"
import seizures3 from "../../images/seizures3.png"
import seizures4 from "../../images/seizures4.png"
import seizures5 from "../../images/seizures5.png"
import seizures6 from "../../images/seizures6.png"
import seizures7 from "../../images/seizures7.png"
import seizures8 from "../../images/seizures8.png"
import seizures9 from "../../images/seizures9.png"

export default () =>
  <div>
    <TopicWraper topic="Seizures">
    <div className="fade">
      <p>During a generalized seizure, your dog may lose consciousness and fall over. Your dog may shake, convulse, chew, lick, urinate, or defecate. Partial seizures may be less obvious, as your dog may remain conscious and show only subtle signs. Some seizures can be life-threatening. If your dog has a seizure, he or she should be evaluated by a veterinarian.</p>
    </div>
    <Carousel header="What and Why">
      <div image={seizures1} boldtext="A seizure occurs because of uncontrolled, abnormal electrical activity in the brain." text="Generalized seizures are most commonly recognized by pet parents and can be frightening to witness. They involve loss of consciousness and uncontrolled movement. Most generalized seizures last less than a couple minutes, but your dog may act differently for up to 24 hours following a seizure. Seizures prime your dog’s brain to have additional seizures, so it is important to bring your dog to a veterinary hospital for evaluation and monitoring as soon as possible after you witness a seizure." />
      <div image={seizures2} boldtext="Other seizures, termed partial or focal seizures, may be less obvious to pet parents." text="They involve a smaller part of your dog’s brain and may only cause odd behavior or muscle twitches. These focal seizures may progress to generalized seizures. Other events may be misrecognized as seizures by pet parents. If you can videotape your pet during a possible seizure event, this may help your veterinarian properly characterize the event." />
      <div image={seizures3} boldtext="A prolonged seizure can cause permanent damage to your dog’s brain or even death." text="Seizures that last more than 5 minutes become increasingly dangerous to your dog’s health. Your veterinarian will have the drugs available to stop a prolonged seizure. At home, you should time the length of your dog’s seizures and share this information with your veterinarian. Do not attempt to restrain your dog or put anything in your dog’s mouth during a seizure." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={seizures4} boldtext="Seizures can happen for many reasons, and identifying the underlying cause of your dog’s seizures is an important part of preventing future seizures." text="Seizures can be a result of disease within your dog’s brain, or they can be a result of metabolic, toxic, or nutritional abnormalities elsewhere in your dog’s body. In some cases, your veterinarian will not be able to identify the cause of the seizures." />
      <div image={seizures5} boldtext="Your veterinarian will attempt identify any underlying disease that may have caused your dog to have a seizure." text="Blood work and specific blood tests will help identify metabolic causes. The age of your dog and progression of the disease will also be important in identifying likely causes. Advanced imaging with MRI or cerebrospinal fluid analysis may also be recommended to rule out structural or infectious diseases, respectively." />
      <div image={seizures6} boldtext="Your veterinarian will be ready to intervene and stop additional seizures with injectable medications." text="These medications quiet the activity in your dog’s brain and help prevent subsequent seizures for a short period of time. If necessary, your veterinarian may start a continuous infusion of a medication to prevent additional seizures. Careful in-hospital monitoring is important as your veterinarian tries to identify the cause of the seizures and initiate therapy with long-term seizure medication." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={seizures7} boldtext="The most important aspect of preventing future seizures is identifying the underlying cause of the seizure." text="Anti-seizure medication will not cure the disease if the cause of your dog’s seizures is an infectious or metabolic disease. Dogs with space-occupying masses in their brain may benefit from additional drugs that reduce inflammation. If no underlying cause is found, anti-seizure medication alone may be the best treatment." />
      <div image={seizures8} boldtext="There are several amazing medications that help prevent seizures." text="Many dogs can eventually be well regulated and go many months seizure-free. Although some lucky dogs may never experience another seizure, most have additional seizures at some point. You should not expect anti-seizure medications to prevent your dog from ever having another seizure. Your dog’s seizures are considered well-controlled if your dog has less than six seizures per year." />
      <div image={seizures9} boldtext="Keep in close contact with your veterinarian as you continue to manage your dog’s seizures." text="Many of the anti-seizure medications take time to accumulate in your dog’s blood, and your veterinarian may need to measure the blood levels of these drugs periodically. Talk to your veterinarian about rescue medications that you can use at home to stop seizures when they occur." />
    </Carousel>
    </TopicWraper>
  </div>;
