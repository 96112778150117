import React, { Component } from 'react';
import CarouselItemSingle from "./CarouselItemSingle";

class CarouselSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fader: "fade"
    }
  };

  componentDidMount() {
    setTimeout(this.unfade, 1000)
  }

  unfade = () => {
    this.setState({
      fader: "no-fade"
    })
  }
  render () {
    return (
      <div>
        <h3>{this.props.header}</h3>
        <CarouselItemSingle current_slide={0} num_slides={1} fader={this.state.fader} image={this.props.children.props.image} boldtext={this.props.children.props.boldtext} text={this.props.children.props.text} />
        <div className="line-break"></div>
      </div>
    );
  }
}
export default CarouselSingle;
