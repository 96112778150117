import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";

import gastropexy1 from "../../images/gastropexy1.png"
import gastropexy2 from "../../images/gastropexy2.png"
import gastropexy3 from "../../images/gastropexy3.png"

export default () =>
  <div>
    <TopicWraper topic="Gastropexy">
    <div className="fade">
      <p>At the time of your dog’s spay or neuter, your veterinarian may recommend a gastropexy. A gastropexy is a surgical procedure that fixes the stomach in its correct orientation. This is performed to prevent the stomach from filling with gas and rotating in place—an emergency condition called gastric dilatation and volvulus (GDV).</p>
    </div>
    <CarouselSingle header="Who is at risk?">
      <div image={gastropexy1} boldtext="Deep-chested dogs like the Great Dane, Boxer, Doberman, Basset Hound, and many others are at increased risk for GDV and may benefit from a prophylactic gastropexy at the time of their spay or neuter." text="Dogs who eat large meals rapidly are also more at risk for GDV. Any dog with a family history of GDV is at increased risk. Talk with your veterinarian about the risk of GDV in your dog and the things you can do to lessen this risk." />
    </CarouselSingle>
    <CarouselSingle header="Why get the gastropexy now?">
      <div image={gastropexy2} boldtext="Gastric dilatation and volvulus usually affects middle-aged to older dogs, but this does not mean you should wait on the gastropexy surgery." text="A GDV incident can happen anytime and will threaten the life of your pet. Emergency surgery to correct a GDV will cost several thousand dollars and may require removal of part of your dog’s stomach. If a gastropexy is recommended by your veterinarian at the time of your dog’s spay or neuter, this procedure will reduce the risk of life-threatening GDV, prevent emergency surgery associated with GDV, and may save you a lot of heartache and money later." />
    </CarouselSingle>
    <CarouselSingle header="What does a gastropexy involve?">
      <div image={gastropexy3} boldtext="A prophylactic gastropexy is a relatively simple surgery and can be easily performed at the time of most spays and neuters." text="If you have a female dog, the same abdominal incision used to perform the spay will be used to access the stomach and secure it in its proper orientation. If you have a male dog, a small incision is often made just behind the ribs. This small window will be used to secure the stomach in its proper place. Several techniques exist to perform this procedure, so talk with your veterinarian about their specific recommendation." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
