import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";
import { Link } from "react-router-dom";

import oa1 from "../../images/oa1.jpg"
import oa2 from "../../images/oa2.jpg"
import oa3 from "../../images/oa3.jpg"
import oa4 from "../../images/oa4.jpg"
import oa5 from "../../images/oa5.jpg"
import oa6 from "../../images/oa6.jpg"
import oa7 from "../../images/oa7.jpg"
import oa8 from "../../images/oa8.jpg"
import oa9 from "../../images/oa9.jpg"

export default () =>
  <div>
    <TopicWraper topic="Osteoarthritis">
    <div className="fade">
      <p>Canine osteoarthritis (OA), also termed degenerative joint disease (DJD), is a common condition in dogs that affects their joints and causes pain. OA is usually first noticed at home when a dog does not walk, run, play, or jump normally. These signs of pain may wax and wane, and some dogs with osteoarthritic changes hide their pain well. Subtle signs may completely disappear at the veterinary office, but a thorough orthopedic examination and other tests can help your veterinarian characterize and treat your dog's osteoarthritis. OA is a complex condition that responds best to multiple medical and non-medical therapies used in combination. Your veterinarian may recommend a combination of treatments including a special diet, medications, joint supplements, and physical rehabilitation.</p>
    </div>
    <Carousel header="What and Why">
      <div image={oa1} boldtext="Osteoarthritis is a condition involving all components of the affected joints and their surrounding structures." text="Pet parents may notice a change in their dog's activity at home. A dog with OA may walk differently, run less, struggle with stairs, or lick and chew certain joints. These signs may gradually worsen, or they might get better or worse with daily changes in activity or the weather. Some dogs with osteoarthritic changes do not show signs of pain." />
      <div image={oa2} boldtext="Osteoarthritis impacts the joint cartilage, underlying bone, and joint fluid." text="Surrounding muscle, tendons and ligaments, and blood supply are also impacted. There is a component of inflammation involving many signaling molecules, and several of these contribute to the pain associated with OA. Obesity not only increases joint stress, it also impacts inflammatory signaling molecules." />
      <div image={oa3} boldtext="Osteoarthritis is complex condition affecting dogs of all ages and can be primary or secondary." text="Our understanding of OA continues to improve and reshape the recommended treatment. Many conditions are associated with or lead to osteoarthritic changes which help us classify the condition as secondary OA. Instability of the joint, often from a previous injury, is a common cause of OA." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={oa4} boldtext="If your dog's clinical signs are consistent with osteoarthritis, your veterinarian will perform an orthopedic examination." text="An orthopedic examination involves thorough palpation of the joints and associated structures while assessing for pain, swelling, and range of motion. Your veterinarian may also perform a neurologic examination, as many orthopedic conditions appear similar to or overlap with neurologic conditions. Additional imaging and blood tests will help diagnose OA and direct treatment." />
      <div image={oa5} boldtext="X-rays, ultrasound, advanced imaging, or arthroscopy are often recommended in cases of osteoarthritis." text="Your veterinarian's recommendation will depend on the individual dog, availability of equipment, and stage of the condition. These tools can help rule out other disease that look like or contribute to OA. Arthroscopy involves introducing a small camera into the joint and can help diagnose and treat certain conditions." />
      <div image={oa6} boldtext="Blood work, including a complete blood count and chemistry, is often recommended prior to starting treatment for osteoarthritis." text="These tests help rule out other diseases. They also help direct treatment of OA, as many of the drugs used require a healthy liver and healthy kidneys. Your veterinarian may suggest additional tests, some of which require collecting fluid from affected joints." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={oa7} boldtext="Your veterinarian may recommend a combination of treatments targeting different components that contribute to osteoarthritis." text="A special diet, pain medications, anti-inflammatories, physical rehabilitation, exercise and weight loss recommendations, joint supplements, and acupuncture are a few of the treatments that may benefit dogs with OA. In combination, these therapies slow the progression of OA and preserve or restore your dog's strength, comfort, and joint function." />
      <div image={oa8} boldtext="Diet and exercise are key components in treating osteoarthritis and improving your dog's long-term comfort." text="Certain prescription-grade fish oils found in prescription veterinary diets can help reduce joint inflammation and stop joint damage. Regular exercise, limited to an amount that does not cause joint pain, will help break the cycle of pain and inflammation associated with osteoarthritis while preserving normal range of motion and activity. Weight control reduces stress on osteoarthritic joints and reduces signaling molecules that contribute to joint pain." />
      <div image={oa9} boldtext="Do not use over-the-counter pain medications to treat your dog's osteoarthritic pain." text="Over-the-counter pain medications, including aspirin and Tylenol can cause deadly gastrointestinal perforation, bleeding, or liver damage. Veterinary pain medications intended for dogs are safer and more effective." />
    </Carousel>
    <div>
    <Link to="/osteoarthritis-management">
    <button className={"soundButton"} style={{fontSize: "1.6em"}}>Osteoarthritis Management</button>
    </Link>
    </div>
    </TopicWraper>
  </div>;
