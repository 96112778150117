import React from 'react';
import TopicWraper from "../TopicWraper";
import CarouselSingle from "../CarouselSingle";

import hypothyroid1 from "../../images/hypothyroid1.jpg"
import hypothyroid2 from "../../images/hypothyroid2.jpg"
import hypothyroid3 from "../../images/hypothyroid3.jpg"

export default () =>
  <div>
    <TopicWraper topic="Hypothyroidism">
    <div className="fade">
      <p>Hypothyroidism is the most common hormone-related disease of dogs.  Dogs with hypothyroidism may act tired, gain weight, have dull hair coats or lose hair.  Low thyroid hormone may be detected on routine blood work in older dogs, but this does not always mean they have true hypothyroidism.</p>
    </div>
    <CarouselSingle header="What and Why">
      <div image={hypothyroid1} boldtext="Dogs with hypothyroidism make less of the thyroid hormones, thyroxine (T4) and triiodothyronine (T3)—hormones with wide biological effects involving cell metabolism, growth, and immune function." text="Hypothyroidism most commonly affects middle-aged to older dogs.  Pet parents often report their dogs are less active, gaining weight, or losing hair.  They may have dandruff or suffer from recurrent skin infections.  Hypothyroidism is most often due to irreversible destruction of the thyroid gland." />
    </CarouselSingle>
    <CarouselSingle header="At Your Veterinarian">
      <div image={hypothyroid2} boldtext="If your veterinarian suspects hypothyroidism, they may suggest blood testing to measure T4." text="T4 can be low for many other reasons, so if it is measured low in your dog, it may be worth rechecking or doing additional confirmatory testing.  Almost any other illness and many drugs can cause T4 levels to be low.  True hypothyroidism can be confirmed by measuring free-T4 and thyroid stimulating hormone (TSH), but these tests may be quite expensive.  Some pet parents elect to start medication without confirmatory blood testing." />
    </CarouselSingle>
    <CarouselSingle header="Treatment">
      <div image={hypothyroid3} boldtext="Hypothyroidism is easily treated by supplementing thyroid hormone with the drug levothyroxine." text="Your dog will need a recheck appointment in about four weeks after starting supplementation and then every few months until his or her thyroid hormone level is appropriate.  Blood sampling at these recheck appointments should be 4-6 hours after you have given thyroid medication.  Once an appropriate dose of levothyroxine is established, dogs with hypothyroidism live otherwise normal lives." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
