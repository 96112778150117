import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import chd1 from "../../images/chd1.jpg"
import chd2 from "../../images/chd2.jpg"
import chd3 from "../../images/chd3.jpg"
import chd4 from "../../images/chd4.jpg"
import chd5 from "../../images/chd5.jpg"
import chd6 from "../../images/chd6.jpg"
import chd7 from "../../images/chd7.jpg"

export default () =>
  <div>
    <TopicWraper topic="Hip Dysplasia">
    <div className="fade">
      <p>In dogs with hip dysplasia, a looseness of the hip joint leads to pain and eventual osteoarthritis.  This looseness of the hip joints can be detected in puppies before they develop osteoarthritis.  There are many surgical and medical options for dogs with hip dysplasia that can be tailored to the individual dog and the severity of disease.</p>
    </div>
    <Carousel header="What and Why">
      <div image={chd1} boldtext="Hip dysplasia is characterized by a looseness of the hip joints and affects dogs of all breeds and ages." text="In young dogs, pet parents may notice hip sway while walking, intermittent hind limb limping or pain, or a reluctance to jump onto furniture or into the car.  Adult dogs show many of the same signs and may appear to be slowing down as their osteoarthritis progresses.  Cranial cruciate ligament injury is more common than hip dysplasia and may present with the same signs." />
      <div image={chd2} boldtext="Hip dysplasia is heritable—passed down genetically from parents to offspring—and thought to be the most common inherited orthopedic condition affecting dogs." text="The most affected breeds include German Shepherds, Goldens, Labs, and many large-breed dogs. Nongenetic factors, such as weight and exercise also play a role in the disease. Overweight dogs experience more pain associated with the looseness in their hips and more frequently develop irreversible osteoarthritis." />
    </Carousel>
    <Carousel header="At your veterinarian">
    <div image={chd3} boldtext="Breeds at increased risk of hip dysplasia, puppies showing signs of possible hip dysplasia, and potential breeding dogs should be screened for hip dysplasia as puppies." text="Loose hip joints can be detected early and measured and help predict future problems and direct a personalized treatment plan. The PennHIP method of hip screening can be used to predict the likelihood of developing hip dysplasia in dogs as early as 16 weeks of age." />
    <div image={chd4} boldtext="An examination and x-ray pictures with your dog under heavy sedation can be used to look for signs of hip dysplasia." text="PennHIP is a method used to measure the looseness of the hip joints with x-ray pictures.  PennHIP requires special equipment and certification, so check with your veterinarian to see if they can offer this." />
    </Carousel>
    <div>
    <a href="https://antechimagingservices.com/antechweb/locate-a-pennhip-veterinarian"  target="_blank" rel="noopener noreferrer">
    <button className={"soundButton"}>Find a PennHIP Veterinarian</button>
    </a>
    </div>
    <Carousel header="Treatment">
    <div image={chd5} boldtext="There are several surgical and medical options to treat hip dysplasia, and these should be tailored to your individual dog." text="In puppies less than 20 weeks of age, a surgical fusion of one of the growth plates in the pelvis can prevent hip dysplasia later in life.  This surgery, called juvenile pubic symphysiodesis (JPS), is highly successful, prevents the pain associated with hip dysplasia, and is far less expensive than long-term medical management of hip dysplasia.  It is the reason puppies should be screened with the PennHIP method before 20 weeks of age." />
    <div image={chd6} boldtext="Other surgical options include the double pelvic osteotomy (DPO), total hip replacement (THR), and femoral head osteotomy (FHO)." text="Double pelvic osteotomy is appropriate for dogs less than 18 months of age with no osteoarthritic changes.  Femoral head osteotomy involves removing the head of the femur, or upper leg bone, and is appropriate for some dogs that already have substantial osteoarthritis.  Total hip replacement involves complete replacement of the hip joint with metal hardware, eliminates pain, and allows normal function." />
    <div image={chd7} boldtext="Medical management of dogs with hip dysplasia is intended to slow the progression of osteoarthritis and reduce the associated pain." text="Osteoarthritis is a complex condition that responds best to multiple medical and non-medical therapies used in combination. Your veterinarian may recommend a combination of treatments including a special diet, medications, joint supplements, and physical rehabilitation.  Additional information about osteoarthritis can be found at PetParentHandouts.com." />
    </Carousel>
    </TopicWraper>
  </div>;
