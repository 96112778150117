import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";
import CarouselSingle from "../CarouselSingle"

import anesthesia1 from "../../images/anesthesia1.jpg"
import anesthesia2 from "../../images/anesthesia2.jpg"
import anesthesia3 from "../../images/anesthesia3.jpg"
import anesthesia4 from "../../images/anesthesia4.jpg"
import anesthesia5 from "../../images/anesthesia5.jpg"
import anesthesia6 from "../../images/anesthesia6.jpg"

export default () =>
  <div>
    <TopicWraper topic="Canine Anesthesia">
    <div className="fade">
      <p>There is some risk associated with putting your dog under anesthesia. Anesthesia is commonly performed in veterinary medicine for everything from routine dental cleanings to emergency surgery. The benefit of these procedures generally outweighs the risk of anesthesia, but dog parents should be aware that there is some risk. Current reports indicate there is about a 0.05% risk of anesthetic complications leading to death. Keep in mind veterinary anesthesia is constantly improving, and many emergency surgery patients would die without anesthesia.</p>
    </div>
    <Carousel header="Before Anesthesia">
      <div image={anesthesia1} boldtext="A preanesthetic workup will reduce some of the risk associated with anesthesia." text="Before your dog undergoes anesthesia, your veterinarian should perform a thorough physical exam, and may require additional tests to improve the safety of the anesthesia. These tests may include blood work, measuring the electrical signal of the heart, genetic tests for drug sensitivities, testing for bleeding disorders, or other tests specific to your dog." />
      <div image={anesthesia2} boldtext="Your veterinarian will generally recommend fasting your dog starting at least the night before the anesthesia." text="Some anesthetic drugs may cause nausea and will make your dog unable to protect his or her airway from unwanted stomach contents. Fasting your dog may help prevent aspiration pneumonia—one of the more common anesthetic risks. It is not necessary to restrict your dog’s access to water prior to anesthesia, and it is safer for your pet to be adequately hydrated during anesthesia." />
      <div image={anesthesia3} boldtext="Preanesthetic blood work is a good screening tool to rule out some systemic diseases." text="Bloodwork may detect changes in your dog's liver or kidneys that could impact the selection of anesthetic drugs used for your pet’s anesthesia. Measuring the packed cell volume and total solids in your dog’s blood will give your veterinarian insight into the hydration status of your pet. Many common anesthetic drugs affect blood pressure and supply of blood to the kidneys, so blood work can be important to prevent potential problems that may arise during your dog’s anesthesia. Your veterinarian is the best source of information for recommendations specific to your dog." />
    </Carousel>
    <Carousel header="Monitoring">
      <div image={anesthesia4} boldtext="Good monitoring during and after anesthesia will reduce some of the risk associated with anesthesia." text="Monitoring equipment and trained personnel can help detect changes in an anesthetized dog before they become a serious problem. Your veterinarian may watch for changes in your dog’s blood pressure, heart rate, blood oxygen level, respiration, temperature, and other parameters. An intravenous (IV) catheter may be placed in one of your dog’s legs to give your veterinarian quick venous access for medical intervention. This IV catheter will also allow your veterinarian to administer fluids during the procedure which can help support your dog’s blood pressure and delivery of oxygen to the tissues during anesthesia." />
      <div image={anesthesia5} boldtext="After anesthesia, your veterinarian will continue to monitor many of the same parameters." text="Oxygen delivery, heart rate, respiratory rate, blood pressure, and temperature are all important parameters to monitor and support for a successful recovery from anesthesia. Many anesthetic complications happen after your dog has awoken from anesthesia. Your dog may be groggy even after it returns home following anesthesia, but if this grogginess persists for more than a day, gets worse, or your dog develops a fever, contact your veterinarian immediately." />
    </Carousel>
    <CarouselSingle header="Pain Management">
      <div image={anesthesia6} boldtext="Appropriate pain management is an important part of successful anesthesia." text="Pain management is one of the pillars of veterinary anesthesia. Unfortunately, inappropriate or absent pain management often accounts for the variation in the cost of anesthesia at different veterinary hospitals. We do not believe that appropriate pain management is an optional part of veterinary anesthesia. New pain medications are safer and more effective than ever for our four-legged family members. Talk to your veterinarian about what pain control will be used during and after anesthesia to ensure your dog’s experience is as pain-free as possible." />
    </CarouselSingle>
    </TopicWraper>
  </div>;
