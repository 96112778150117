import React from 'react';
import TopicWraper from "../TopicWraper";
import Carousel from "../Carousel";

import cancer1 from "../../images/cancer1.jpg"
import cancer2 from "../../images/cancer2.jpg"
import cancer3 from "../../images/cancer3.jpg"
import cancer4 from "../../images/cancer4.jpg"
import cancer5 from "../../images/cancer5.jpg"
import cancer6 from "../../images/cancer6.jpg"
import cancer7 from "../../images/cancer7.jpg"

export default () =>
  <div>
    <TopicWraper topic="Cancer in Dogs">
    <div className="fade">
      <p>Dogs, like humans, get cancer. Cancer comes from a bad population of your dog’s own cells. Some of these cancers are benign, or cause no harm, but some are malignant and do cause harm. Our understanding of and ability to treat cancer in dogs has improved dramatically over the last decade.</p>
    </div>
    <Carousel header="What and Why">
      <div image={cancer1} boldtext="Cancer development is multifactorial and multistep." text="Many factors contribute to the development of cancer, including genetic factors, environmental factors, hormones, viruses, parasites, and chronic inflammation or infection. Your dog has many defense mechanisms to protect against cancer, so several steps are usually required for cancer to successfully establish itself. Several more steps are required for that cancer to develop and threaten your dog’s health." />
      <div image={cancer2} boldtext="Cancer is often, but not always, a disease of older dogs." text="As your dog ages, it accumulates more genetic mutations in its cells, and some of these can lead to cancer. Some of the lumps, bumps, and skin tags your dog develops as it ages may be harmless, but it is generally best to bring these to the attention of your veterinarian who can take a closer look at some of the cells under a microscope." />
      <div image={cancer3} boldtext="Not all cancers look the same." text="Some cancers cause masses that are visible and are easily noticed by pet parents at home. Other cancers cause systemic signs and may be detected on blood work or imaging. Some cancers can be detected early in the disease process; these cancers are usually more responsive to treatment than cancers that are detected late." />
    </Carousel>
    <Carousel header="At your veterinarian">
      <div image={cancer4} boldtext="Your veterinarian’s goals are to diagnose the type of cancer and understand the behavior of the cancer." text="The type of cancer can help you anticipate what to expect. Not all cancers behave the same in all dogs, but we have a pretty good understanding of how certain cancers typically behave." />
      <div image={cancer5} boldtext="Additional tests may help your veterinarian correctly identify and characterize your dog’s cancer." text="Common tests include poking the cancer with a needle for microscopic evaluation, surgically removing a cancerous mass to be sent off for examination, and molecular profiling of the cancer by an outside lab. Blood work and imaging can also be useful for characterizing the location and behavior of the cancer." />
    </Carousel>
    <Carousel header="Treatment">
      <div image={cancer6} boldtext="Cancer treatment options include chemotherapy, surgical oncology, and radiation therapy." text="Although some of these treatments work best for certain types of cancers, most cancers respond best to a combination of treatments. If warranted, your veterinarian should be able to refer you to a veterinary specialty hospital that can offer all these treatment types. Anti-inflammatory and immunosuppressive drugs are also useful to treat certain types of cancer. Early detection and intervention are important factors in the successful treatment of cancer, so do not hesitate to have your veterinarian examine any new masses or growths that your dog develops." />
      <div image={cancer7} boldtext="Some cancer treatments are only intended to improve your dog’s quality of life and will not cure the cancer." text="These treatments are termed “palliative” and may be a good option to relieve some of the pain the cancer is causing." />
    </Carousel>
    </TopicWraper>
  </div>;
